* {
  font-family: "Lato", sans-serif;
}

@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

.eye{}

.notxns{
   color: #686868;
}

.log {
  text-decoration: underline;
  cursor: pointer;
}

.log:hover {
  text-decoration: underline;
  cursor: pointer;
  text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.proceed:hover {
  background: #8c40dd 0% 0% no-repeat padding-box;
}

.proceed:active {
  background: #7a32c4;
  /* Darken the color on active */
  transform: scale(0.98);
  /* Slightly reduce the size to simulate a press */
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
  /* Add an inset shadow for a pressed effect */
}

.popupContainerPDF {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100vh;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.popup-contentPDF {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  background-color: rgb(255, 255, 255);
  width: 90%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 20px;
  border-radius: 20px;
  height: 80vh;
  overflow-y: scroll;
  color: black;
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}

.txtPDF {
  padding-left: 20px;
  padding-right: 20px;
  margin-left: auto;
  margin-right: auto;
  text-align: justify;
}

.popup-contentPDF h2 {
  font-weight: bolder;
  font-size: 25px;
  text-decoration: underline;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  text-align: left;
}

.mainTDIV {
  font-size: 25px;
  font-weight: bold;
  text-decoration: underline;
  text-align: center;
}

.popup-contentPDF p {

  padding-left: 20px;
  padding-right: 20px;
  text-align: justify;
}

.popup-contentPDF h4 {
  font-weight: bolder;
  font-size: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.downloadSaft {
  width: 100%;
  position: sticky;
  bottom: 0;
  background-color: #962EEB;
  border: none;
  border-radius: 100px;
  color: white;
  font-weight: bolder;
  padding: 5px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  transition: 0.3s ease-in-out;
}

.downloadSaft:hover {
  background: #8c40dd 0% 0% no-repeat padding-box;
}

.downloadSaft:active {
  background: #7a32c4;
  /* Darken the color on active */
  transform: scale(0.98);
  /* Slightly reduce the size to simulate a press */
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
  /* Add an inset shadow for a pressed effect */
}

.mainT {
  text-align: center;
  color: black;
}

.close-btn {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.close-btn:hover,
.close-btn:focus {
  color: #000;
}

@keyframes popupAnim {
  from {
    transform: scale(0.7);
    opacity: 0;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
}


.pdfCPm {
  display: none;
}

body {
  background-color: #000002;
}

.invest:disabled {
  opacity: 0.4;
  cursor: no-drop;
}

.bold {
  font-weight: bold;
  color: white;
}

.userMob:hover {
  cursor: pointer;
}

#txtLeft {
  text-align: left;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: #000000;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 0 0 20px 20px #19191900;
  background-color: #19191900;
}

@media screen and (max-width: 499px) {
  .adminInputs {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .proceed {
    background-color: #8c40dd;
    color: white;
    margin: auto;
    display: block;
    border: none;
    padding: 10px;
    border-radius: 100px;
    width: 250px;
    transition: 0.3s ease-in-out;
  }
  
  #confirmPledgeForm {
    margin-top: 40px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .admin-panel {
    background-color: #2c2c2c;
    border: 1px solid #b9b9b9;
    border-radius: 8px;
    padding: 20px;
    width: 400px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .admin-panel h2 {
    color: #AD5EFF;
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .form-group {
    margin-bottom: 15px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .form-group label {
    display: block;
    font-size: 14px;
    margin-bottom: 10px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .form-group input {
    width: 90%;
    padding: 8px;
    border: 1px solid #b9b9b9;
    border-radius: 4px;
    background-color: #1a1a1a;
    color: #b9b9b9;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .form-group input::placeholder {
    color: #616161;
  }

  .form-group input[type="number"] {
    -moz-appearance: textfield;
  }

  .form-group input[type="number"]::-webkit-outer-spin-button,
  .form-group input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .form-group button {
    width: 90%;
    padding: 10px;
    border: none;
    border-radius: 4px;
    background-color: #AD5EFF;
    color: white;
    font-size: 16px;
    cursor: pointer;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .form-group button:hover {
    background-color: #962EEB;
  }

  .responseSuccess {
    font-weight: 700;
    text-align: center;
  }

  .review {
    color: #5fb619;
    font-weight: 500;
  }

  .review2 {
    color: red;
    font-weight: 500;
  }



  .popup-containerMain3 {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100vh;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .popup-container3 {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.5);
    width: 90%;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }


  .success {
    background-color: #ffffff;
    border-color: #d6e9c6;
  }

  .close2 {
    right: 5px;
    margin-top: 5px;
    cursor: pointer;
    font-size: 24px;
    z-index: 50;
    color: #b9b9b9;
    transition: 0.3s ease-in-out;
    text-align: right;
  }

  .close2:hover {
    color: #a0a0a0;
  }


  .regsiterFlex {
    display: flex;
    justify-content: space-evenly;
    flex-flow: column nowrap;
  }

  /* Overlay for dimming the background */
  .register-overlay,
  .login-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.63);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
  }

  .register-popup {
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    padding-top: 0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    max-width: 90%;
    width: 90%;
    text-align: center;
  }

  /* General pop-up styling */
  .login-popup {
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    padding-top: 0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    max-width: 400px;
    width: 90%;
    text-align: center;
  }

  /* Header styling */
  .register-popup h2,
  .login-popup h2 {
    font-size: 26px;
    margin-bottom: 20px;
    font-weight: bold;
  }

  /* Input fields styling */
  .register-popup input[type="text"],
  .register-popup input[type="email"],
  .register-popup input[type="password"],
  .login-popup input[type="text"],
  .login-popup input[type="password"] {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
    color: #262626;
    outline-color: #8c40dd;
  }

  .optionCountries {
    outline-color: #8c40dd;
    text-align: left;
  }

  .register-popup input::placeholder {
    color: #5f5f5f;
  }

  .login-popup input::placeholder {
    color: #5f5f5f;
  }

  /* Button styling */
  .register-popup .register-btn,
  .login-popup .login-btn {
    background-color: #AD5EFF;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 20px;
    transition: 0.3s ease-in-out;
  }

  .register-popup .register-btn,
  .login-popup .login-btn:hover {
    background: #8c40dd 0% 0% no-repeat padding-box;
  }

  .register-popup .register-btn,
  .login-popup .login-btn:active {
    background: #7a32c4;
    /* Darken the color on active */
    transform: scale(0.98);
    /* Slightly reduce the size to simulate a press */
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
    /* Add an inset shadow for a pressed effect */
  }

  /* Cancel and forgot password link styling */
  .register-popup .cancel-btn,
  .login-popup .forgot-password {
    color: #666666;
    margin-top: 10px;
    display: inline-block;
    cursor: pointer;
    font-size: 14px;
    margin-left: 10px;
    text-decoration: underline;
  }

  #remember-me2 {
    margin-left: 5px;
  }

  .accountCreate {
    margin-top: 25px;
    color: #363636;
    font-size: 14px;
  }

  .rh {
    font-weight: 600;
    color: #AD5EFF;
    text-decoration: underline;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    margin-left: 5px;
  }

  .rh:hover {
    color: #c666fd;
  }

  .allWrap {
    background-color: #000002;
  }

  .allWrapMain {
    background-color: #000002;
  }

  .light {
    background-color: #000002;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    height: 100vh;
    height: max-content;
    justify-content: center;
    padding-bottom: 15vh;
  }

  .connectT {
    color: white;
    background-color: #AD5EFF;
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    text-decoration: underline;
    padding: 2px;
    font-weight: 500;
    cursor: pointer;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    border-radius: 100px;
    transition: 0.3s ease-in-out;
    display: none;
  }

  .connectT:hover {
    background: #8c40dd 0% 0% no-repeat padding-box;
  }

  .connectT:active {
    background: #7a32c4;
    /* Darken the color on active */
    transform: scale(0.98);
    /* Slightly reduce the size to simulate a press */
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
    /* Add an inset shadow for a pressed effect */
  }

  .leftSection {
    border: 0.3px solid #FCFCFC;
    border-radius: 10px;
    width: 300px;
    height: 90vh;
    height: max-content;
    margin-top: 15px;
    margin-left: 15px;
    position: sticky;
    top: 20px;
    margin-bottom: 20px;
    display: none;
  }

  .logo {
    width: 110px;
  }

  .headerMob {
    display: flex;
    width: 90%;
    justify-content: space-between;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 10px;
  }

  .logoDiv {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .userMob {
    display: flex;
    border: 1px solid #616161;
    color: #616161;
    padding: 10px;
    border-radius: 100px;
    font-weight: 600;
    padding-left: 20px;
    padding-right: 20px;
    width: fit-content;
    margin-top: auto;
    margin-bottom: auto;
    font-size: 14px;
  }

  .userMob img {
    width: 20px;
    height: 20px;
    margin-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .section1 {
    border-top: #fcfcfc71 0.3px solid;
  }


  .pagesDiv {
    width: 100%;
    color: white;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-evenly;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .eachDivNav {
    width: 80px;
  }

  #wallet {
    width: 50px;
    height: 50px;
    transition: transform .5s;
  }

  #wallet:hover {
    transform: scale(1.1);
  }

  #walletDiv {
    margin-top: -38px;

  }

  .navBar {
    background-color: black;
    z-index: 5;
    position: fixed;
    right: 0;
    bottom: 0;
    width: 100%;
    margin-top: 0;
  }

  .onlyMob {
    background-color: black;
    z-index: 5;
    position: sticky;
    right: 0;
    bottom: 0;
  }

  .pagesDiv div {
    color: white;
    font-size: 11px;
    margin-top: auto;
    margin-bottom: auto;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    text-align: center;

  }

  .pagesDiv-2 {
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    margin-top: 6vh;
    margin-bottom: 7vh;
    color: #727070;
  }

  .pagesDiv-2 div {
    color: #727070;
    font-size: 14px;
    margin-top: auto;
    margin-bottom: auto;
    margin: 10px;
    cursor: pointer;
    transition: 0.3s ease-in-out;
  }


  .headerNav {
    color: white;
  }

  .pagesDiv div:hover {
    color: #919191;
  }

  .pagesDiv img {
    width: 21px;
    height: 21px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .pagesDiv-2 img {
    width: 18px;
    height: 18px;
    margin-top: -5px;
    margin-right: 14px;
  }

  .section3Headers {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
  }

  .section3HeadersMain {
    border: 1px solid #AD5EFF;
    width: fit-content;
    padding: 10px;
    border-radius: 25px;
    width: 90%;
    cursor: pointer;
    padding-top: 15px;
    padding-bottom: 15px;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
  }

  .amountSection {
    margin-top: 10px;
  }

  .ghutAmount2 {
    font-size: 25px;
    font-weight: 100;
    color: rgb(228, 228, 228);
    margin-left: auto;
    margin-right: auto;
    text-align: left;
    margin-left: 15px;
  }

  .maticAmount {
    font-size: 22px;
    font-weight: 100;
    color: rgb(228, 228, 228);
    margin-left: 15px;
  }

  #amountHL {
    color: white;
    font-weight: 700;
  }

  .section3Headers img {
    width: 15px;
    height: 15px;
    margin-top: auto;
    margin-bottom: auto;
  }

  #txtLeft {
    color: white;
    font-size: 14px;
    text-align: left;
    margin-left: 15px;
  }

  .grayTxt {
    color: #727070;
    transition: 0.3s ease-in-out;
    font-size: 16px;
  }

  .grayTxt:hover {
    color: #bbbbbb;
  }

  .section4 {
    margin-top: 10vh;
  }

  .title1Rsection {
    font-size: 16px;
    text-align: center;
  }

  .page1Main {
    display: flex;
    justify-content: space-between;
  }

  .mainT {
    color: #727070;
    margin-bottom: 15px;
    display: none;
  }

  .rightSection {
    margin-right: auto;
    margin-left: auto;
    width: 100%;
    padding: 10px;
  }

  .rightSection--2 {
    display: flex;
    justify-content: space-between;
    text-align: center;
  }

  #txtCenter {
    text-align: center;
  }

  .rightSection-1 {
    width: 100%;
    text-align: center;
  }

  .rightSection-2 {
    width: 30%;
    margin-right: auto;
    display: none;
  }

  .rightSection-2-2 {
    width: 100%;
  }

  .title1Rsection {
    text-align: left;
    color: #FCFCFC;
  }

  .title2Rsection {
    color: #AD5EFF;
    font-size: 18px;
  }

  .details {
    font-size: 14px;
    margin-top: 10px;
  }

  #de1 {
    color: white;
    font-weight: 500;
  }

  #de2 {
    color: rgb(173, 173, 173);
    font-weight: 500;
  }

  #de3 {
    color: rgb(128, 128, 128);
    font-weight: 500;
  }

  .note {
    font-size: 14px;
    color: #727070ec;
    margin-top: 10px;
  }

  .flexBox {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    flex-flow: column nowrap;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .purpleBox {
    background-color: #AD5EFF;
    border-radius: 20px;
    padding: 15px;
    width: 100%;
    margin-bottom: 25px;
  }

  .blackBox {
    background-color: rgb(0, 0, 0);
    width: 100%;
    margin-top: auto;
    margin-bottom: auto;
    color: white;
    padding: 5px;
  }

  .boxT1 {
    font-size: 18px;
    font-weight: 700;
  }

  .boxT2 {
    font-size: 23px;
    font-weight: bolder;
  }

  .boxT3 {
    font-size: 16px;
    font-weight: 400;
    margin-top: 10px;
  }

  .giftSection {
    font-size: 14px;
    margin-top: 17px;
    display: flex;
    justify-content: center;
  }

  .giftSection img {
    width: 22px;
    height: 22px;
  }

  .giftSectionP1 {
    font-weight: bold;
  }

  .underline {
    text-decoration: underline;
    cursor: pointer;
    transition: 0.3s ease-in-out;
  }

  .underline:hover {
    color: black;
  }

  .bbTitle {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 10px;
    font-weight: 700;
  }

  #bbTitle2 {
    font-weight: 300;
  }

  .bbTitle3 {
    font-size: 14px;
  }

  .box2Main {
    width: 85%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .imgs2_1 {
    width: 80px;
    margin-right: 10px;
  }

  .imgs2_2 {
    width: 40px;
    margin-left: 10px;

  }

  .imgs2BB {
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
    justify-content: center;
  }

  .line {
    width: 100%;
    height: 1px;
    background-color: rgba(255, 255, 255, 0.26);
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .flexBox2T {
    font-size: 16px;
    color: #AD5EFF;
    font-weight: 500;
    margin-bottom: 30px;
  }

  .flexBox3T {
    font-size: 22px;
    color: #AD5EFF;
    font-weight: 500;
    font-weight: 300;
    display: none;
  }

  .flexBox4T {
    font-size: 25px;
    color: #ffffff;
    font-weight: 300;
    margin-bottom: 30px;
    width: 100%;
    display: none;
  }

  .amountBox1 {
    border: 0.5px solid #fcfcfc6e;
    opacity: 1;
    border-radius: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 50%;
    padding: 12px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

  }

  .amountBox2 {
    width: 50%;
    display: flex;
    justify-content: center;
    /* Center horizontally */
    align-items: center;
    /* Center vertically */
    border: 0.5px solid #fcfcfc6e;
    border-left: 0.5px solid #fcfcfc00;
    opacity: 1;
    border-radius: 5px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

  }

  .amountBoxMain {
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
    color: white;
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .sendCoin {
    font-size: 14px;
    font-weight: 400;
    margin-top: -23px;
    background-color: black;
    width: fit-content;
    font-weight: 300;
  }

  .amount {
    font-size: 25px;
    font-weight: 400;
    text-align: center;
  }

  .amountBox button {
    background: var(--violeta) 0% 0% no-repeat padding-box;
    background: #AD5EFF 0% 0% no-repeat padding-box;
    border-radius: 5px;
    opacity: 1;
    border: none;
    color: white;
    width: 100%;
    padding: 5px;
    transition: 0.3s ease-in-out;
  }

  .amountBox button:hover {
    background: #8c40dd 0% 0% no-repeat padding-box;
  }

  .amountBox button:active {
    background: #7a32c4;
    /* Darken the color on active */
    transform: scale(0.98);
    /* Slightly reduce the size to simulate a press */
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
    /* Add an inset shadow for a pressed effect */
  }

  .usdtAmount {
    width: 30px;
    height: 30px;
  }

  .ghutAmount {
    width: 30px;
    height: 30px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .line2 {
    width: 8px;
    height: 100%;
    background-color: #727070;
  }

  .amount input {
    background-color: black;
    width: 100%;
    border: none;
    outline: none;
    color: white;
    text-align: center;
  }

  ::placeholder {
    color: white;
  }

  .progress-container {
    margin-top: 10px;
    width: 100%;
    background-color: white;
    border-radius: 100px;
  }

  .progress-bar {
    background-color: rgb(206, 206, 206);
    height: 20px;
    border-radius: 100px;
    padding: 11px;
    font-size: 14px;
  }

  .progress-container2 {
    margin-top: 10px;
    width: 100%;
    background-color: white;
    border-radius: 100px;

  }

  ._softCapTargetPercentage {
    background-color: #000000;
    border-radius: 100px;
    font-size: 12px;
    text-align: center;
    font-weight: bold;
    margin-top: -21px;
    color: white;
    border: 1px solid rgb(0, 0, 0);
  }

  .noteMm {
    text-align: center;
    color: white;
    margin-top: 25px;
  }

  .installMm {
    text-align: center;
    color: white;
    text-decoration: underline;
    transition: 0.3s ease-in-out;
  }

  .installMm:hover {
    cursor: pointer;
    color: #727070;
  }

  .transaction-list-main {
    background-color: #141414;
    /* Dark background */
    border-radius: 10px;
    padding: 10px;
    width: 100%;
    border: 1px solid rgba(37, 37, 37, 0.068);
    display: none;
  }

  .transaction-list-main-mob {
    background-color: #141414;
    /* Dark background */
    border-radius: 10px;
    padding: 10px;
    width: 100%;
    border: 1px solid rgba(37, 37, 37, 0.068);
  }

  .flexDoc1 {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 12px;
    width: fit-content;
  }

  .flexDoc2 {
    display: flex;
    justify-content: space-between;
  }

  .uploadMain {
    background-color: #141414;
    /* Dark background */
    border-radius: 10px;
    padding: 10px;
    width: 100%;
    border: 1px solid rgba(37, 37, 37, 0.068);
  }

  .transaction-list {
    width: 100%;
    margin: 20px;
    margin-top: 25px;
    padding-bottom: 25px;
    margin-left: auto;
    margin-right: auto;
    border-bottom: 1px solid #3636369f;
  }

  .list1 {
    display: flex;
    margin-right: 15px;
  }

  .list1 img {
    width: 25px;
    height: 25px;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 7px;
  }

  .list1Con {
    color: #8c40dd;
    font-weight: 500;
    font-size: 14px;
  }

  .list1Con2 {
    color: #616161;
    font-weight: 300;
    font-size: 14px;
  }

  .listConMain {
    margin-top: auto;
    margin-bottom: auto;
  }

  .listConMain2 {
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    color: #c7c7c7;
    margin-top: auto;
    margin-bottom: auto;
  }

  .listConMain2-2 {
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 10px;
    font-size: 15px;
    display: none;
  }

  .listConMain3 {
    display: flex;
    background-color: #262626;
    color: #c7c7c7;
    width: 100px;
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 10px;
    font-size: 14px;
  }

  .listConMain3 img {
    width: 15px;
    height: 15px;
    margin-top: auto;
    margin-bottom: auto;
    cursor: pointer;
    transition: transform .2s;
  }

  .listConMain3 img:hover {
    transform: scale(1.1);
  }

  .listBtn:hover {
    background: #8c40dd 0% 0% no-repeat padding-box;
  }

  .listBtn:active {
    background: #7a32c4;
    /* Darken the color on active */
    transform: scale(0.98);
    /* Slightly reduce the size to simulate a press */
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
    /* Add an inset shadow for a pressed effect */
  }

  .list1Con3 {
    color: #616161;
    font-size: 14px;
  }

  .listBtn {
    background-color: #ac5eff00;
    border: 1px solid #AD5EFF;
    border-radius: 15px;
    color: white;
    font-weight: bold;
    width: 60px;
    padding: 5px;
    transition: 0.3s ease-in-out;
    font-size: 13px;
    width: 50%;
    text-align: left;
    padding-left: 12px;
  }

  .listT {
    font-weight: 700;
    color: white;
    width: 100%;
    margin-left: 2%;
  }

  .line3 {
    width: 100%;
    height: 2px;
    background-color: rgba(255, 255, 255, 0.418);
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .listNote {
    text-align: center;
    color: #AD5EFF;
    font-size: 12px;
    margin-top: 5%;
  }

  .profile {
    display: flex;
    justify-content: space-between;
    margin-top: 5%;
  }

  .upload {
    margin-top: auto;
    margin-bottom: auto;
  }

  .upload img {
    width: 90px;
  }

  .upload div {
    color: #AD5EFF;
    text-align: center;
    font-size: 12px;
  }

  label {
    color: #AD5EFF;
    text-align: center;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    flex-flow: column nowrap;
  }

  label:hover {
    color: #bf87fc;
  }

  .upload2 {
    width: 65%;
  }

  .editInputArea {
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;
    margin-top: 15px;
    border-bottom: #363636 1px solid;
    margin-left: 10px;
  }

  .editHere {
    display: flex;
  }

  .editHere div {
    margin-top: auto;
    margin-bottom: auto;
    color: #AD5EFF;
    margin-left: 5px;
    font-size: 12px;
    cursor: pointer;
  }

  .editHere img {
    width: 14px;
    height: 14px;
    margin-top: auto;
    margin-bottom: auto;
    cursor: pointer;
  }

  .editInputArea input {
    background-color: #00000200;
    width: 100%;
    border: none;
    outline: none;
    font-size: 14px;
    color: #dadada;
  }

  .editInputArea input::placeholder {
    color: #616161;
  }

  .editT {
    color: #616161;
    font-size: 10px;
    font-weight: bolder;
  }

  .grid {
    width: 8%;
    z-index: 5;
    position: fixed;
    right: 0;
    bottom: 0;
    display: none;
  }

  .popup-containerMain {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 5;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100vh;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    animation: fadeIn 0.4s ease-in-out forwards;

  }

  .close {
    position: absolute;
    top: 5px;
    right: 10px;
    cursor: pointer;
    font-size: 22px;
    z-index: 50;
    color: #b9b9b9;
    transition: 0.3s ease-in-out;
  }

  .close:hover {
    color: #a0a0a0;
  }

  .popup-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 5;
    background-color: rgba(0, 0, 0, 0.5);
    width: 350px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: #009144;
    padding: 20px;
    border-radius: 10px;
    width: 95%;
  }

  .popup-container2 {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 5;
    background-color: rgba(0, 0, 0, 0.5);
    width: 350px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: #E90000;
    padding: 20px;
    border-radius: 10px;
    width: 95%;
  }

  .popup-container3 {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 5;
    background-color: rgba(0, 0, 0, 0.5);
    width: 95%;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: #ffffff;
    padding: 30px;
    border-radius: 10px;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translate(-50%, -45%);
    }

    100% {
      opacity: 1;
      transform: translate(-50%, -50%);
    }
  }

  .validateT {
    color: #FCFCFC;
    opacity: 1;
    text-align: center;
    font-weight: 500;
    margin-top: 20px;
    font-size: 15px;
    text-transform: uppercase;
    font-weight: bold;
  }

  .errorT {
    color: #FCFCFC;
    opacity: 1;
    text-align: center;
    font-weight: 500;
    margin-top: 10px;
    font-size: 17px;
    font-weight: bold;
    text-transform: uppercase;
  }

  .editTPopUp {
    text-align: left;
    color: #262626;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px;
  }

  .validateInputDiv {
    flex-flow: column nowrap;
    display: flex;
    margin-bottom: 20px;
    margin-top: 30px;
  }

  .validateInputDiv input {
    border: 2px solid rgba(255, 255, 255, 0.726);
    color: white;
    opacity: 1;
    margin-bottom: 10px;
    border-radius: 7px;
    background-color: #36363600;
    padding: 5px;
    padding-left: 15px;
    outline: none;
  }


  .validateInputDiv2 {
    flex-flow: column nowrap;
    display: flex;
    margin-bottom: 20px;
    margin-top: 30px;
  }

  .validateInputDiv2 input {
    border: 2px solid rgba(255, 255, 255, 0.726);
    color: rgba(44, 44, 44, 0);
    opacity: 1;
    margin-bottom: 10px;
    background-color: #36363600;
    border-bottom: 1px solid #3f3f3f54;
    outline: none;
    padding-bottom: 7px;
    color: #191919;
  }

  .validateInputDiv2 input::placeholder {
    color: #191919;
  }

  .validate {
    background-color: white;
    color: black;
    border-radius: 10px;
    border: none;
    padding: 7px;
    font-weight: bold;
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 120px;
  }

  .validate:hover {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transition: 0.3s ease-in-out;
  }

  .validate:active {
    background: #cccccc;
    transform: scale(0.98);
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
  }

  .errorFlexBtns {
    display: flex;
    justify-content: center;
    margin-top: 35px;
  }

  .errorP {
    color: white;
    opacity: 1;
    text-align: center;
    margin-top: 10px;
  }

  .saveEdits {
    background-color: #AD5EFF;
    color: rgb(255, 255, 255);
    border-radius: 10px;
    border: none;
    padding: 7px;
    display: block;
    width: 120px;
    margin-left: auto;
    transition: 0.3s ease-in-out;
  }

  .saveEdits:hover {
    background: #8c40dd 0% 0% no-repeat padding-box;
  }

  .saveEdits:active {
    background: #7a32c4;
    transform: scale(0.98);
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
  }

  .softCapTargetPercentage {
    background-color: #009144;
  }
}

@media screen and (min-width: 500px) and (max-width: 767px) {
  .onlyMob {
    background-color: black;
    z-index: 5;
    position: sticky;
    right: 0;
    bottom: 0;
  }
  .adminInputs {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .proceed {
    background-color: #8c40dd;
    color: white;
    margin: auto;
    display: block;
    border: none;
    padding: 10px;
    border-radius: 100px;
    width: 250px;
    transition: 0.3s ease-in-out;
  }
  
  #confirmPledgeForm {
    margin-top: 40px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .admin-panel {
    background-color: #2c2c2c;
    border: 1px solid #b9b9b9;
    border-radius: 8px;
    padding: 20px;
    width: 400px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .admin-panel h2 {
    color: #AD5EFF;
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .form-group {
    margin-bottom: 15px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .form-group label {
    display: block;
    font-size: 14px;
    margin-bottom: 10px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .form-group input {
    width: 90%;
    padding: 8px;
    border: 1px solid #b9b9b9;
    border-radius: 4px;
    background-color: #1a1a1a;
    color: #b9b9b9;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .form-group input::placeholder {
    color: #616161;
  }

  .form-group input[type="number"] {
    -moz-appearance: textfield;
  }

  .form-group input[type="number"]::-webkit-outer-spin-button,
  .form-group input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .form-group button {
    width: 90%;
    padding: 10px;
    border: none;
    border-radius: 4px;
    background-color: #AD5EFF;
    color: white;
    font-size: 16px;
    cursor: pointer;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .form-group button:hover {
    background-color: #962EEB;
  }

  .responseSuccess {
    font-weight: 700;
    text-align: center;
  }

  .review {
    color: #5fb619;
    font-weight: 500;
    text-align: center;
  }

  .review2 {
    color: red;
    font-weight: 500;
    text-align: center;
  }

  .popup-containerMain3 {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100vh;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .popup-container3 {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.5);
    width: 30%;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }


  .success {
    background-color: #ffffff;
    border-color: #d6e9c6;
  }

  .close2 {
    right: 5px;
    margin-top: 5px;
    cursor: pointer;
    font-size: 24px;
    z-index: 50;
    color: #b9b9b9;
    transition: 0.3s ease-in-out;
    text-align: right;
  }

  .close2:hover {
    color: #a0a0a0;
  }

  .regsiterFlex {
    display: flex;
    justify-content: space-evenly;
  }

  /* Overlay for dimming the background */
  .register-overlay,
  .login-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.63);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
  }

  .register-popup {
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    padding-top: 0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    max-width: 90%;
    width: 90%;
    text-align: center;
  }

  .leftSectionWidth {
    width: 50%;
  }

  .rightSectionWidth {
    width: 30%;
  }

  /* General pop-up styling */
  .login-popup {
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    padding-top: 0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    max-width: 400px;
    width: 90%;
    text-align: center;
  }

  /* Header styling */
  .register-popup h2,
  .login-popup h2 {
    font-size: 26px;
    margin-bottom: 20px;
    font-weight: bold;
  }

  /* Input fields styling */
  .register-popup input[type="text"],
  .register-popup input[type="email"],
  .register-popup input[type="password"],
  .login-popup input[type="text"],
  .login-popup input[type="password"] {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
    color: #262626;
    outline-color: #8c40dd;
  }

  .optionCountries {
    outline-color: #8c40dd;
    text-align: left;
  }

  .register-popup input::placeholder {
    color: #5f5f5f;
  }

  .login-popup input::placeholder {
    color: #5f5f5f;
  }

  /* Button styling */
  .register-popup .register-btn,
  .login-popup .login-btn {
    background-color: #AD5EFF;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 20px;
    transition: 0.3s ease-in-out;
  }

  .register-popup .register-btn,
  .login-popup .login-btn:hover {
    background: #8c40dd 0% 0% no-repeat padding-box;
  }

  .register-popup .register-btn,
  .login-popup .login-btn:active {
    background: #7a32c4;
    /* Darken the color on active */
    transform: scale(0.98);
    /* Slightly reduce the size to simulate a press */
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
    /* Add an inset shadow for a pressed effect */
  }

  /* Cancel and forgot password link styling */
  .register-popup .cancel-btn,
  .login-popup .forgot-password {
    color: #666666;
    margin-top: 10px;
    display: inline-block;
    cursor: pointer;
    font-size: 14px;
    margin-left: 10px;
    text-decoration: underline;
  }

  #remember-me2 {
    margin-left: 5px;
  }

  .accountCreate {
    margin-top: 25px;
    color: #363636;
    font-size: 14px;
  }

  .rh {
    font-weight: 600;
    color: #AD5EFF;
    text-decoration: underline;
    cursor: pointer;
    transition: 0.3s ease-in-out;
  }

  .rh:hover {
    color: #c666fd;
  }

  .allWrap {
    background-color: #000002;
    height: 100vh;
  }

  .allWrapMain {
    background-color: #000002;
  }

  .light {
    background-color: #000002;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    height: 100vh;
    height: max-content;
    justify-content: center;
    padding-bottom: 17vh;
  }

  .connectT {
    color: white;
    background-color: #AD5EFF;
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    text-decoration: underline;
    padding: 2px;
    font-weight: 500;
    cursor: pointer;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    border-radius: 100px;
    transition: 0.3s ease-in-out;
    display: none;
  }

  .connectT:hover {
    background: #8c40dd 0% 0% no-repeat padding-box;
  }

  .connectT:active {
    background: #7a32c4;
    /* Darken the color on active */
    transform: scale(0.98);
    /* Slightly reduce the size to simulate a press */
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
    /* Add an inset shadow for a pressed effect */
  }

  .leftSection {
    border: 0.3px solid #FCFCFC;
    border-radius: 10px;
    width: 300px;
    height: 90vh;
    height: max-content;
    margin-top: 15px;
    margin-left: 15px;
    position: sticky;
    top: 20px;
    margin-bottom: 20px;
    display: none;
  }

  .logo {
    width: 118px;
  }

  .headerMob {
    display: flex;
    width: 90%;
    justify-content: space-between;
    margin-right: auto;
    margin-left: auto;
  }

  .logoDiv {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .userMob {
    display: flex;
    border: 1px solid #616161;
    color: #616161;
    padding: 10px;
    border-radius: 100px;
    font-weight: 600;
    padding-left: 20px;
    padding-right: 20px;
    width: fit-content;
    margin-top: auto;
    margin-bottom: auto;
  }

  .userMob img {
    width: 20px;
    height: 20px;
    margin-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .section1 {
    border-top: #fcfcfc71 0.3px solid;
  }


  .pagesDiv {
    width: 100%;
    color: white;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-evenly;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .eachDivNav {
    width: 80px;
  }

  #wallet {
    width: 55px;
    height: 55px;
    transition: transform .5s;
  }

  #wallet:hover {
    transform: scale(1.1);
  }

  #walletDiv {
    margin-top: -38px;

  }

  .navBar {
    background-color: black;
    z-index: 5;
    position: fixed;
    right: 0;
    bottom: 0;
    width: 100%;
    margin-top: 0;
  }

  .pagesDiv div {
    color: white;
    font-size: 11px;
    margin-top: auto;
    margin-bottom: auto;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    text-align: center;

  }

  .pagesDiv-2 {
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    margin-top: 6vh;
    margin-bottom: 7vh;
    color: #727070;
  }

  .pagesDiv-2 div {
    color: #727070;
    font-size: 14px;
    margin-top: auto;
    margin-bottom: auto;
    margin: 10px;
    cursor: pointer;
    transition: 0.3s ease-in-out;
  }


  .headerNav {
    color: white;
  }

  .pagesDiv div:hover {
    color: #919191;
  }

  .pagesDiv img {
    width: 21px;
    height: 21px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .pagesDiv-2 img {
    width: 18px;
    height: 18px;
    margin-top: -5px;
    margin-right: 14px;
  }

  .section3Headers {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
  }

  .section3HeadersMain {
    border: 1px solid #AD5EFF;
    width: fit-content;
    padding: 10px;
    border-radius: 25px;
    width: 90%;
    cursor: pointer;
    padding-top: 15px;
    padding-bottom: 15px;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
  }

  .amountSection {
    margin-top: 10px;
  }

  .ghutAmount2 {
    font-size: 25px;
    font-weight: 100;
    color: rgb(228, 228, 228);
    margin-left: auto;
    margin-right: auto;
    text-align: left;
    margin-left: 15px;
  }

  .maticAmount {
    font-size: 22px;
    font-weight: 100;
    color: rgb(228, 228, 228);
    margin-left: 15px;
  }

  #amountHL {
    color: white;
    font-weight: 700;
  }

  .section3Headers img {
    width: 15px;
    height: 15px;
    margin-top: auto;
    margin-bottom: auto;
  }

  #txtLeft {
    color: white;
    font-size: 14px;
    text-align: left;
    margin-left: 15px;
  }

  .grayTxt {
    color: #727070;
    transition: 0.3s ease-in-out;
    font-size: 18px;
  }

  .grayTxt:hover {
    color: #bbbbbb;
  }

  .section4 {
    margin-top: 10vh;
  }

  .title1Rsection {
    font-size: 20px;
    text-align: center;
  }

  .page1Main {
    display: flex;
    justify-content: space-between;
  }

  .mainT {
    color: #727070;
    margin-bottom: 15px;
    display: none;
  }

  .rightSection {
    margin-right: auto;
    margin-left: auto;
    width: 100%;
    padding: 20px;
  }

  .rightSection--2 {
    display: flex;
    justify-content: space-between;
    text-align: center;
  }

  #txtCenter {
    text-align: center;
  }

  .rightSection-1 {
    width: 100%;
    text-align: center;
  }

  .rightSection-2 {
    width: 30%;
    margin-right: auto;
    display: none;
  }

  .rightSection-2-2 {
    width: 100%;
  }

  .title1Rsection {
    text-align: left;
    color: #FCFCFC;
  }

  .title2Rsection {
    color: #AD5EFF;
    font-size: 18px;
  }

  .details {
    font-size: 14px;
    margin-top: 10px;
  }

  #de1 {
    color: white;
    font-weight: 500;
  }

  #de2 {
    color: rgb(173, 173, 173);
    font-weight: 500;
  }

  #de3 {
    color: rgb(128, 128, 128);
    font-weight: 500;
  }

  .note {
    font-size: 14px;
    color: #727070ec;
    margin-top: 10px;
  }

  .flexBox {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    flex-flow: column nowrap;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .purpleBox {
    background-color: #AD5EFF;
    border-radius: 20px;
    padding: 15px;
    width: 100%;
    margin-bottom: 25px;
  }

  .blackBox {
    background-color: rgb(0, 0, 0);
    width: 100%;
    margin-top: auto;
    margin-bottom: auto;
    color: white;
    padding: 5px;
  }

  .boxT1 {
    font-size: 18px;
    font-weight: 700;
  }

  .boxT2 {
    font-size: 23px;
    font-weight: bolder;
  }

  .boxT3 {
    font-size: 16px;
    font-weight: 400;
    margin-top: 10px;
  }

  .giftSection {
    font-size: 14px;
    margin-top: 17px;
    display: flex;
    justify-content: center;
  }

  .giftSection img {
    width: 22px;
    height: 22px;
    margin-right: 10px;
  }

  .giftSectionP1 {
    font-weight: bold;
  }

  .underline {
    text-decoration: underline;
    cursor: pointer;
    transition: 0.3s ease-in-out;
  }

  .underline:hover {
    color: black;
  }

  .bbTitle {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 10px;
    font-weight: 700;
  }

  #bbTitle2 {
    font-weight: 300;
  }

  .bbTitle3 {
    font-size: 14px;
  }

  .box2Main {
    width: 85%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .imgs2_1 {
    width: 80px;
    margin-right: 10px;
  }

  .imgs2_2 {
    width: 40px;
    margin-left: 10px;

  }

  .imgs2BB {
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
    justify-content: center;
  }

  .line {
    width: 100%;
    height: 1px;
    background-color: rgba(255, 255, 255, 0.26);
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .flexBox2T {
    font-size: 16px;
    color: #AD5EFF;
    font-weight: 500;
    margin-bottom: 30px;
  }

  .flexBox3T {
    font-size: 22px;
    color: #AD5EFF;
    font-weight: 500;
    font-weight: 300;
    display: none;
  }

  .flexBox4T {
    font-size: 25px;
    color: #ffffff;
    font-weight: 300;
    margin-bottom: 30px;
    width: 100%;
    display: none;
  }

  .amountBox1 {
    border: 0.5px solid #fcfcfc6e;
    opacity: 1;
    border-radius: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 50%;
    padding: 12px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

  }

  .amountBox2 {
    width: 50%;
    display: flex;
    justify-content: center;
    /* Center horizontally */
    align-items: center;
    /* Center vertically */
    border: 0.5px solid #fcfcfc6e;
    border-left: 0.5px solid #fcfcfc00;
    opacity: 1;
    border-radius: 5px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

  }

  .amountBoxMain {
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
    color: white;
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .sendCoin {
    font-size: 14px;
    font-weight: 400;
    margin-top: -23px;
    background-color: black;
    width: fit-content;
    font-weight: 300;
  }

  .amount {
    font-size: 25px;
    font-weight: 400;
    text-align: center;
  }

  .amountBox button {
    background: var(--violeta) 0% 0% no-repeat padding-box;
    background: #AD5EFF 0% 0% no-repeat padding-box;
    border-radius: 5px;
    opacity: 1;
    border: none;
    color: white;
    width: 100%;
    padding: 5px;
    transition: 0.3s ease-in-out;
  }

  .amountBox button:hover {
    background: #8c40dd 0% 0% no-repeat padding-box;
  }

  .amountBox button:active {
    background: #7a32c4;
    /* Darken the color on active */
    transform: scale(0.98);
    /* Slightly reduce the size to simulate a press */
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
    /* Add an inset shadow for a pressed effect */
  }

  .usdtAmount {
    width: 30px;
    height: 30px;
  }

  .ghutAmount {
    width: 30px;
    height: 30px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .line2 {
    width: 8px;
    height: 100%;
    background-color: #727070;
  }

  .amount input {
    background-color: black;
    width: 100%;
    border: none;
    outline: none;
    color: white;
    text-align: center;
  }

  ::placeholder {
    color: white;
  }

  .progress-container {
    margin-top: 10px;
    width: 100%;
    background-color: white;
    border-radius: 100px;
  }

  .progress-bar {
    background-color: rgb(206, 206, 206);
    height: 20px;
    border-radius: 100px;
    padding: 11px;
    font-size: 14px;
  }

  .progress-container2 {
    margin-top: 10px;
    width: 100%;
    background-color: white;
    border-radius: 100px;

  }

  ._softCapTargetPercentage {
    background-color: #000000;
    border-radius: 100px;
    font-size: 12px;
    text-align: center;
    font-weight: bold;
    margin-top: -21px;
    color: white;
    border: 1px solid rgb(0, 0, 0);
  }

  .noteMm {
    text-align: center;
    color: white;
    margin-top: 25px;
  }

  .installMm {
    text-align: center;
    color: white;
    text-decoration: underline;
    transition: 0.3s ease-in-out;
  }

  .installMm:hover {
    cursor: pointer;
    color: #727070;
  }

  .transaction-list-main {
    background-color: #141414;
    /* Dark background */
    border-radius: 10px;
    padding: 10px;
    width: 100%;
    border: 1px solid rgba(37, 37, 37, 0.068);
    display: none;
  }

  .transaction-list-main-mob {
    background-color: #141414;
    /* Dark background */
    border-radius: 10px;
    padding: 10px;
    width: 100%;
    border: 1px solid rgba(37, 37, 37, 0.068);
  }

  .flexDoc1 {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 12px;
    width: fit-content;
  }

  .flexDoc2 {
    display: flex;
    justify-content: space-between;
  }

  .uploadMain {
    background-color: #141414;
    /* Dark background */
    border-radius: 10px;
    padding: 10px;
    width: 100%;
    border: 1px solid rgba(37, 37, 37, 0.068);
  }

  .transaction-list {
    width: 100%;
    margin: 20px;
    margin-top: 25px;
    padding-bottom: 25px;
    margin-left: auto;
    margin-right: auto;
    border-bottom: 1px solid #3636369f;
  }

  .list1 {
    display: flex;
    margin-right: 15px;
  }

  .list1 img {
    width: 25px;
    height: 25px;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 7px;
  }

  .list1Con {
    color: #8c40dd;
    font-weight: 500;
    font-size: 14px;
  }

  .list1Con2 {
    color: #616161;
    font-weight: 300;
    font-size: 14px;
  }

  .listConMain {
    margin-top: auto;
    margin-bottom: auto;
  }

  .listConMain2 {
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    color: #c7c7c7;
    margin-top: auto;
    margin-bottom: auto;
  }

  .listConMain2-2 {
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 10px;
    font-size: 15px;
    display: none;
  }

  .listConMain3 {
    display: flex;
    background-color: #262626;
    color: #c7c7c7;
    width: 100px;
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 10px;
    font-size: 14px;
  }

  .listConMain3 img {
    width: 15px;
    height: 15px;
    margin-top: auto;
    margin-bottom: auto;
    cursor: pointer;
    transition: transform .2s;
  }

  .listConMain3 img:hover {
    transform: scale(1.1);
  }

  .listBtn:hover {
    background: #8c40dd 0% 0% no-repeat padding-box;
  }

  .listBtn:active {
    background: #7a32c4;
    /* Darken the color on active */
    transform: scale(0.98);
    /* Slightly reduce the size to simulate a press */
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
    /* Add an inset shadow for a pressed effect */
  }

  .list1Con3 {
    color: #616161;
    font-size: 14px;
  }

  .listBtn {
    background-color: #ac5eff00;
    border: 1px solid #AD5EFF;
    border-radius: 15px;
    color: white;
    font-weight: bold;
    width: 60px;
    padding: 5px;
    transition: 0.3s ease-in-out;
    font-size: 13px;
    width: 50%;
    text-align: left;
    padding-left: 12px;
  }

  .listT {
    font-weight: 700;
    color: white;
    width: 100%;
    margin-left: 2%;
  }

  .line3 {
    width: 100%;
    height: 2px;
    background-color: rgba(255, 255, 255, 0.418);
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .listNote {
    text-align: center;
    color: #AD5EFF;
    font-size: 12px;
    margin-top: 5%;
  }

  .profile {
    display: flex;
    justify-content: space-between;
    margin-top: 5%;
  }

  .upload {
    margin-top: auto;
    margin-bottom: auto;
  }

  .upload img {
    width: 100px;
  }

  .upload div {
    color: #AD5EFF;
    text-align: center;
    font-size: 14px;
  }

  label {
    color: #AD5EFF;
    text-align: center;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    flex-flow: column nowrap;
  }

  label:hover {
    color: #bf87fc;
  }

  .upload2 {
    width: 65%;
  }

  .editInputArea {
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;
    margin-top: 15px;
    border-bottom: #363636 1px solid;
    margin-left: 10px;
  }

  .editHere {
    display: flex;
  }

  .editHere div {
    margin-top: auto;
    margin-bottom: auto;
    color: #AD5EFF;
    margin-left: 10px;
    font-size: 14px;
    cursor: pointer;
  }

  .editHere img {
    width: 18px;
    height: 18px;
    margin-top: auto;
    margin-bottom: auto;
    cursor: pointer;
  }

  .editInputArea input {
    background-color: #00000200;
    width: 100%;
    border: none;
    outline: none;
    font-size: 16px;
    color: #dadada;
  }

  .editInputArea input::placeholder {
    color: #616161;
  }

  .editT {
    color: #616161;
    font-size: 12px;
    font-weight: bolder;
  }

  .grid {
    width: 8%;
    z-index: 5;
    position: fixed;
    right: 0;
    bottom: 0;
    display: none;
  }

  .popup-containerMain {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 5;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100vh;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    animation: fadeIn 0.4s ease-in-out forwards;

  }

  .close {
    position: absolute;
    top: 5px;
    right: 10px;
    cursor: pointer;
    font-size: 22px;
    z-index: 50;
    color: #b9b9b9;
    transition: 0.3s ease-in-out;
  }

  .close:hover {
    color: #a0a0a0;
  }

  .popup-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 5;
    background-color: rgba(0, 0, 0, 0.5);
    width: 350px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: #009144;
    padding: 20px;
    border-radius: 10px;
  }

  .popup-container2 {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 5;
    background-color: rgba(0, 0, 0, 0.5);
    width: 350px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: #E90000;
    padding: 20px;
    border-radius: 10px;
  }

  .popup-container3 {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 5;
    background-color: rgba(0, 0, 0, 0.5);
    width: 450px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: #ffffff;
    padding: 30px;
    border-radius: 10px;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translate(-50%, -45%);
    }

    100% {
      opacity: 1;
      transform: translate(-50%, -50%);
    }
  }

  .validateT {
    color: #FCFCFC;
    opacity: 1;
    text-align: center;
    font-weight: 500;
    margin-top: 20px;
    font-size: 15px;
    text-transform: uppercase;
    font-weight: bold;
  }

  .errorT {
    color: #FCFCFC;
    opacity: 1;
    text-align: center;
    font-weight: 500;
    margin-top: 10px;
    font-size: 17px;
    font-weight: bold;
    text-transform: uppercase;
  }

  .editTPopUp {
    text-align: left;
    color: #262626;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px;
  }

  .validateInputDiv {
    flex-flow: column nowrap;
    display: flex;
    margin-bottom: 20px;
    margin-top: 30px;
  }

  .validateInputDiv input {
    border: 2px solid rgba(255, 255, 255, 0.726);
    color: white;
    opacity: 1;
    margin-bottom: 10px;
    border-radius: 7px;
    background-color: #36363600;
    padding: 5px;
    padding-left: 15px;
    outline: none;
  }


  .validateInputDiv2 {
    flex-flow: column nowrap;
    display: flex;
    margin-bottom: 20px;
    margin-top: 30px;
  }

  .validateInputDiv2 input {
    border: 2px solid rgba(255, 255, 255, 0.726);
    color: rgba(44, 44, 44, 0);
    opacity: 1;
    margin-bottom: 10px;
    background-color: #36363600;
    border-bottom: 1px solid #3f3f3f54;
    outline: none;
    padding-bottom: 7px;
    color: #191919;
  }

  .validateInputDiv2 input::placeholder {
    color: #191919;
  }

  .validate {
    background-color: white;
    color: black;
    border-radius: 10px;
    border: none;
    padding: 7px;
    font-weight: bold;
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 120px;
  }

  .validate:hover {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transition: 0.3s ease-in-out;
  }

  .validate:active {
    background: #cccccc;
    transform: scale(0.98);
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
  }

  .errorFlexBtns {
    display: flex;
    justify-content: center;
    margin-top: 35px;
  }

  .errorP {
    color: white;
    opacity: 1;
    text-align: center;
    margin-top: 10px;
  }

  .saveEdits {
    background-color: #AD5EFF;
    color: rgb(255, 255, 255);
    border-radius: 10px;
    border: none;
    padding: 7px;
    display: block;
    width: 120px;
    margin-left: auto;
    transition: 0.3s ease-in-out;
  }

  .saveEdits:hover {
    background: #8c40dd 0% 0% no-repeat padding-box;
  }

  .saveEdits:active {
    background: #7a32c4;
    transform: scale(0.98);
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
  }

  .softCapTargetPercentage {
    background-color: #009144;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {

  .adminInputs {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  .onlyMob {
    background-color: black;
    z-index: 5;
    position: sticky;
    right: 0;
    bottom: 0;
  }
  .proceed {
    background-color: #8c40dd;
    color: white;
    margin: auto;
    display: block;
    border: none;
    padding: 10px;
    border-radius: 100px;
    width: 250px;
    transition: 0.3s ease-in-out;
  }
  
  #confirmPledgeForm {
    margin-top: 40px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .admin-panel {
    background-color: #2c2c2c;
    border: 1px solid #b9b9b9;
    border-radius: 8px;
    padding: 20px;
    width: 400px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .admin-panel h2 {
    color: #AD5EFF;
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .form-group {
    margin-bottom: 15px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .form-group label {
    display: block;
    font-size: 14px;
    margin-bottom: 10px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .form-group input {
    width: 90%;
    padding: 8px;
    border: 1px solid #b9b9b9;
    border-radius: 4px;
    background-color: #1a1a1a;
    color: #b9b9b9;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .form-group input::placeholder {
    color: #616161;
  }

  .form-group input[type="number"] {
    -moz-appearance: textfield;
  }

  .form-group input[type="number"]::-webkit-outer-spin-button,
  .form-group input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .form-group button {
    width: 90%;
    padding: 10px;
    border: none;
    border-radius: 4px;
    background-color: #AD5EFF;
    color: white;
    font-size: 16px;
    cursor: pointer;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .form-group button:hover {
    background-color: #962EEB;
  }

  .responseSuccess {
    font-weight: 700;
    text-align: center;
  }

  .review {
    color: #5fb619;
    font-weight: 500;
    text-align: center;
  }

  .review2 {
    color: red;
    font-weight: 500;
    text-align: center;
  }

  .popup-containerMain3 {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100vh;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .popup-container3 {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.5);
    width: 30%;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }


  .success {
    background-color: #ffffff;
    border-color: #d6e9c6;
  }

  .close2 {
    right: 5px;
    margin-top: 5px;
    cursor: pointer;
    font-size: 24px;
    z-index: 50;
    color: #b9b9b9;
    transition: 0.3s ease-in-out;
    text-align: right;
  }

  .close2:hover {
    color: #a0a0a0;
  }


  .regsiterFlex {
    display: flex;
    justify-content: space-evenly;
  }

  /* Overlay for dimming the background */
  .register-overlay,
  .login-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.63);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
  }

  .register-popup {
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    padding-top: 0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    max-width: 90%;
    width: 90%;
    text-align: center;
  }

  .leftSectionWidth {
    width: 50%;
  }

  .rightSectionWidth {
    width: 30%;
  }

  /* General pop-up styling */
  .login-popup {
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    padding-top: 0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    max-width: 400px;
    width: 90%;
    text-align: center;
  }

  /* Header styling */
  .register-popup h2,
  .login-popup h2 {
    font-size: 26px;
    margin-bottom: 20px;
    font-weight: bold;
  }

  /* Input fields styling */
  .register-popup input[type="text"],
  .register-popup input[type="email"],
  .register-popup input[type="password"],
  .login-popup input[type="text"],
  .login-popup input[type="password"] {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
    color: #262626;
    outline-color: #8c40dd;
  }

  .optionCountries {
    outline-color: #8c40dd;
    text-align: left;
  }

  .register-popup input::placeholder {
    color: #5f5f5f;
  }

  .login-popup input::placeholder {
    color: #5f5f5f;
  }

  /* Button styling */
  .register-popup .register-btn,
  .login-popup .login-btn {
    background-color: #AD5EFF;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 20px;
    transition: 0.3s ease-in-out;
  }

  .register-popup .register-btn,
  .login-popup .login-btn:hover {
    background: #8c40dd 0% 0% no-repeat padding-box;
  }

  .register-popup .register-btn,
  .login-popup .login-btn:active {
    background: #7a32c4;
    /* Darken the color on active */
    transform: scale(0.98);
    /* Slightly reduce the size to simulate a press */
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
    /* Add an inset shadow for a pressed effect */
  }

  /* Cancel and forgot password link styling */
  .register-popup .cancel-btn,
  .login-popup .forgot-password {
    color: #666666;
    margin-top: 10px;
    display: inline-block;
    cursor: pointer;
    font-size: 14px;
    margin-left: 10px;
    text-decoration: underline;
  }

  #remember-me2 {
    margin-left: 5px;
  }

  .accountCreate {
    margin-top: 25px;
    color: #363636;
    font-size: 14px;
  }

  .rh {
    font-weight: 600;
    color: #AD5EFF;
    text-decoration: underline;
    cursor: pointer;
    transition: 0.3s ease-in-out;
  }

  .rh:hover {
    color: #c666fd;
  }

  .allWrap {
    background-color: #000002;
    height: 100vh;
  }

  .allWrapMain {
    background-color: #000002;
  }

  .light {
    background-color: #000002;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    height: 100vh;
    height: max-content;
    justify-content: center;
    padding-bottom: 18vh;
  }

  .connectT {
    color: white;
    background-color: #AD5EFF;
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    text-decoration: underline;
    padding: 2px;
    font-weight: 500;
    cursor: pointer;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    border-radius: 100px;
    transition: 0.3s ease-in-out;
    display: none;
  }

  .connectT:hover {
    background: #8c40dd 0% 0% no-repeat padding-box;
  }

  .connectT:active {
    background: #7a32c4;
    /* Darken the color on active */
    transform: scale(0.98);
    /* Slightly reduce the size to simulate a press */
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
    /* Add an inset shadow for a pressed effect */
  }

  .leftSection {
    border: 0.3px solid #FCFCFC;
    border-radius: 10px;
    width: 300px;
    height: 90vh;
    height: max-content;
    margin-top: 15px;
    margin-left: 15px;
    position: sticky;
    top: 20px;
    margin-bottom: 20px;
    display: none;
  }

  .logo {
    width: 118px;
  }

  .headerMob {
    display: flex;
    width: 90%;
    justify-content: space-between;
    margin-right: auto;
    margin-left: auto;
  }

  .logoDiv {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .userMob {
    display: flex;
    border: 1px solid #616161;
    color: #616161;
    padding: 10px;
    border-radius: 100px;
    font-weight: 600;
    padding-left: 20px;
    padding-right: 20px;
    width: fit-content;
    margin-top: auto;
    margin-bottom: auto;
  }

  .userMob img {
    width: 20px;
    height: 20px;
    margin-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .section1 {
    border-top: #fcfcfc71 0.3px solid;
  }


  .pagesDiv {
    width: 100%;
    color: white;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-evenly;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .eachDivNav {
    width: 100px;
  }

  #wallet {
    width: 60px;
    height: 60px;
    transition: transform .5s;
  }

  #wallet:hover {
    transform: scale(1.1);
  }

  #walletDiv {
    margin-top: -38px;

  }

  .navBar {
    background-color: black;
    z-index: 5;
    position: fixed;
    right: 0;
    bottom: 0;
    width: 100%;
    margin-top: 0;
  }

  .pagesDiv div {
    color: white;
    font-size: 14px;
    margin-top: auto;
    margin-bottom: auto;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    text-align: center;

  }

  .pagesDiv-2 {
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    margin-top: 6vh;
    margin-bottom: 7vh;
    color: #727070;
  }

  .pagesDiv-2 div {
    color: #727070;
    font-size: 14px;
    margin-top: auto;
    margin-bottom: auto;
    margin: 10px;
    cursor: pointer;
    transition: 0.3s ease-in-out;
  }


  .headerNav {
    color: white;
  }

  .pagesDiv div:hover {
    color: #919191;
  }

  .pagesDiv img {
    width: 25px;
    height: 25px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .pagesDiv-2 img {
    width: 18px;
    height: 18px;
    margin-top: -5px;
    margin-right: 14px;
  }

  .section3Headers {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
  }

  .section3HeadersMain {
    border: 1px solid #AD5EFF;
    width: fit-content;
    padding: 10px;
    border-radius: 25px;
    width: 90%;
    cursor: pointer;
    padding-top: 15px;
    padding-bottom: 15px;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
  }

  .amountSection {
    margin-top: 10px;
  }

  .ghutAmount2 {
    font-size: 30px;
    font-weight: 100;
    color: rgb(228, 228, 228);
    margin-left: auto;
    margin-right: auto;
    text-align: left;
    margin-left: 15px;
  }

  .maticAmount {
    font-size: 25px;
    font-weight: 100;
    color: rgb(228, 228, 228);
    margin-left: 15px;
  }

  #amountHL {
    color: white;
    font-weight: 700;
  }

  .section3Headers img {
    width: 15px;
    height: 15px;
    margin-top: auto;
    margin-bottom: auto;
  }

  #txtLeft {
    color: white;
    font-size: 14px;
    text-align: left;
    margin-left: 15px;
  }

  .grayTxt {
    color: #727070;
    transition: 0.3s ease-in-out;
    font-size: 18px;
  }

  .grayTxt:hover {
    color: #bbbbbb;
  }

  .section4 {
    margin-top: 10vh;
  }

  .title1Rsection {
    font-size: 23px;
    text-align: center;
  }

  .page1Main {
    display: flex;
    justify-content: space-between;
  }

  .mainT {
    color: #727070;
    margin-bottom: 15px;
    display: none;
  }

  .rightSection {
    margin-right: auto;
    margin-left: auto;
    width: 100%;
    padding: 20px;
  }

  .rightSection--2 {
    display: flex;
    justify-content: space-between;
    text-align: center;
  }

  #txtCenter {
    text-align: center;
  }

  .rightSection-1 {
    width: 100%;
    text-align: center;
  }

  .rightSection-2 {
    width: 30%;
    margin-right: auto;
    display: none;
  }

  .rightSection-2-2 {
    width: 80%;
    margin-left: 1%;
  }

  .title1Rsection {
    text-align: left;
    color: #FCFCFC;
  }

  .title2Rsection {
    color: #AD5EFF;
    font-size: 20px;
  }

  .details {
    font-size: 16px;
    margin-top: 10px;
  }

  #de1 {
    color: white;
    font-weight: 500;
  }

  #de2 {
    color: rgb(173, 173, 173);
    font-weight: 500;
  }

  #de3 {
    color: rgb(128, 128, 128);
    font-weight: 500;
  }

  .note {
    font-size: 16px;
    color: #727070ec;
    margin-top: 10px;
  }

  .flexBox {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    flex-flow: column nowrap;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
  }

  .purpleBox {
    background-color: #AD5EFF;
    border-radius: 20px;
    padding: 15px;
    width: 100%;
    margin-bottom: 25px;
  }

  .blackBox {
    background-color: rgb(0, 0, 0);
    width: 100%;
    margin-top: auto;
    margin-bottom: auto;
    color: white;
    padding: 5px;
  }

  .boxT1 {
    font-size: 18px;
    font-weight: 700;
  }

  .boxT2 {
    font-size: 23px;
    font-weight: bolder;
  }

  .boxT3 {
    font-size: 16px;
    font-weight: 400;
    margin-top: 10px;
  }

  .giftSection {
    font-size: 14px;
    margin-top: 17px;
    display: flex;
    justify-content: center;
  }

  .giftSection img {
    width: 18px;
    height: 18px;
    margin-right: 10px;
  }

  .giftSectionP1 {
    font-weight: bold;
  }

  .underline {
    text-decoration: underline;
    cursor: pointer;
    transition: 0.3s ease-in-out;
  }

  .underline:hover {
    color: black;
  }

  .bbTitle {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 10px;
    font-weight: 700;
  }

  #bbTitle2 {
    font-weight: 300;
  }

  .bbTitle3 {
    font-size: 14px;
  }

  .box2Main {
    width: 85%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .imgs2_1 {
    width: 80px;
    margin-right: 10px;
  }

  .imgs2_2 {
    width: 40px;
    margin-left: 10px;

  }

  .imgs2BB {
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
    justify-content: center;
  }

  .line {
    width: 100%;
    height: 1px;
    background-color: rgba(255, 255, 255, 0.26);
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .flexBox2T {
    font-size: 16px;
    color: #AD5EFF;
    font-weight: 500;
    margin-bottom: 30px;
  }

  .flexBox3T {
    font-size: 22px;
    color: #AD5EFF;
    font-weight: 500;
    font-weight: 300;
    display: none;
  }

  .flexBox4T {
    font-size: 25px;
    color: #ffffff;
    font-weight: 300;
    margin-bottom: 30px;
    width: 100%;
    display: none;
  }

  .amountBox1 {
    border: 0.5px solid #fcfcfc6e;
    opacity: 1;
    border-radius: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 50%;
    padding: 12px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

  }

  .amountBox2 {
    width: 50%;
    display: flex;
    justify-content: center;
    /* Center horizontally */
    align-items: center;
    /* Center vertically */
    border: 0.5px solid #fcfcfc6e;
    border-left: 0.5px solid #fcfcfc00;
    opacity: 1;
    border-radius: 5px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

  }

  .amountBoxMain {
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
    color: white;
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .sendCoin {
    font-size: 14px;
    font-weight: 400;
    margin-top: -23px;
    background-color: black;
    width: fit-content;
    font-weight: 300;
  }

  .amount {
    font-size: 25px;
    font-weight: 400;
    text-align: center;
  }

  .amountBox button {
    background: var(--violeta) 0% 0% no-repeat padding-box;
    background: #AD5EFF 0% 0% no-repeat padding-box;
    border-radius: 5px;
    opacity: 1;
    border: none;
    color: white;
    width: 100%;
    padding: 5px;
    transition: 0.3s ease-in-out;
  }

  .amountBox button:hover {
    background: #8c40dd 0% 0% no-repeat padding-box;
  }

  .amountBox button:active {
    background: #7a32c4;
    /* Darken the color on active */
    transform: scale(0.98);
    /* Slightly reduce the size to simulate a press */
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
    /* Add an inset shadow for a pressed effect */
  }

  .usdtAmount {
    width: 30px;
    height: 30px;
  }

  .ghutAmount {
    width: 30px;
    height: 30px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .line2 {
    width: 8px;
    height: 100%;
    background-color: #727070;
  }

  .amount input {
    background-color: black;
    width: 100%;
    border: none;
    outline: none;
    color: white;
    text-align: center;
  }

  ::placeholder {
    color: white;
  }

  .progress-container {
    margin-top: 10px;
    width: 100%;
    background-color: white;
    border-radius: 100px;
  }

  .progress-bar {
    background-color: rgb(206, 206, 206);
    height: 20px;
    border-radius: 100px;
    padding: 11px;
    font-size: 14px;
  }

  .progress-container2 {
    margin-top: 10px;
    width: 100%;
    background-color: white;
    border-radius: 100px;

  }

  ._softCapTargetPercentage {
    background-color: #000000;
    border-radius: 100px;
    font-size: 12px;
    text-align: center;
    font-weight: bold;
    margin-top: -21px;
    color: white;
    border: 1px solid rgb(0, 0, 0);
  }

  .noteMm {
    text-align: center;
    color: white;
    margin-top: 25px;
  }

  .installMm {
    text-align: center;
    color: white;
    text-decoration: underline;
    transition: 0.3s ease-in-out;
  }

  .installMm:hover {
    cursor: pointer;
    color: #727070;
  }
  .transaction-list-main-mob {
    background-color: #141414;
    /* Dark background */
    border-radius: 10px;
    padding: 10px;
    width: 100%;
    border: 1px solid rgba(37, 37, 37, 0.068);
    
  }
  .transaction-list-main {
    background-color: #141414;
    /* Dark background */
    border-radius: 10px;
    padding: 10px;
    width: 100%;
    border: 1px solid rgba(37, 37, 37, 0.068);
    display: none;
  }

  .uploadMain {
    background-color: #141414;
    /* Dark background */
    border-radius: 10px;
    padding: 10px;
    width: 95%;
    border: 1px solid rgba(37, 37, 37, 0.068);
  }

  .transaction-list {
    display: flex;
    width: 97%;
    justify-content: space-between;
    margin: 20px;
    margin-top: 25px;
    margin-bottom: 25px;
    margin-left: auto;
    margin-right: auto;
  }

  .list1 {
    display: flex;
  }

  .list1 img {
    width: 25px;
    height: 25px;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 7px;
  }

  .list1Con {
    color: #8c40dd;
    font-weight: 500;
    font-size: 14px;
  }

  .list1Con2 {
    color: #616161;
    font-weight: 300;
    font-size: 14px;
  }

  .listConMain {
    margin-top: auto;
    margin-bottom: auto;
  }

  .listConMain2 {
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    color: #c7c7c7;
    margin-top: auto;
    margin-bottom: auto;
  }

  .listConMain2-2 {
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 10px;
    font-size: 15px;
  }

  .listConMain3 {
    display: flex;
    background-color: #262626;
    color: #c7c7c7;
    width: 100px;
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 10px;
    font-size: 14px;
  }

  .listConMain3 img {
    width: 15px;
    height: 15px;
    margin-top: auto;
    margin-bottom: auto;
    cursor: pointer;
    transition: transform .2s;
  }

  .listConMain3 img:hover {
    transform: scale(1.1);
  }

  .listBtn:hover {
    background: #8c40dd 0% 0% no-repeat padding-box;
  }

  .listBtn:active {
    background: #7a32c4;
    /* Darken the color on active */
    transform: scale(0.98);
    /* Slightly reduce the size to simulate a press */
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
    /* Add an inset shadow for a pressed effect */
  }

  .list1Con3 {
    color: #616161;
    font-size: 14px;
  }

  .listBtn {
    background-color: #AD5EFF;
    border: none;
    border-radius: 7px;
    color: white;
    font-weight: bold;
    width: 60px;
    padding: 5px;
    transition: 0.3s ease-in-out;
    font-size: 13px;

  }

  .listT {
    font-weight: 700;
    color: white;
    width: 100%;
    margin-left: 2%;
  }

  .line3 {
    width: 100%;
    height: 2px;
    background-color: rgba(255, 255, 255, 0.418);
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .listNote {
    text-align: center;
    color: #AD5EFF;
    font-size: 12px;
    margin-top: 5%;
  }

  .profile {
    display: flex;
    justify-content: space-between;
    margin-top: 5%;
  }

  .upload img {
    width: 150px;
  }

  .upload div {
    color: #AD5EFF;
    text-align: center;
  }

  label {
    color: #AD5EFF;
    text-align: center;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    flex-flow: column nowrap;
  }

  label:hover {
    color: #bf87fc;
  }

  .upload2 {
    width: 70%;
  }

  .editInputArea {
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;
    margin-top: 15px;
    border-bottom: #363636 1px solid;
    margin-left: 10px;
  }

  .editHere {
    display: flex;
  }

  .editHere div {
    margin-top: auto;
    margin-bottom: auto;
    color: #AD5EFF;
    margin-left: 10px;
    font-size: 14px;
    cursor: pointer;
  }

  .editHere img {
    width: 18px;
    height: 18px;
    margin-top: auto;
    margin-bottom: auto;
    cursor: pointer;
  }

  .editInputArea input {
    background-color: #00000200;
    width: 100%;
    border: none;
    outline: none;
    font-size: 16px;
    color: #dadada;
  }

  .editInputArea input::placeholder {
    color: #616161;
  }

  .editT {
    color: #616161;
    font-size: 12px;
    font-weight: bolder;
  }

  .grid {
    width: 8%;
    z-index: 5;
    position: fixed;
    right: 0;
    bottom: 0;
    display: none;
  }

  .popup-containerMain {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 5;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100vh;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    animation: fadeIn 0.4s ease-in-out forwards;

  }

  .close {
    position: absolute;
    top: 5px;
    right: 10px;
    cursor: pointer;
    font-size: 22px;
    z-index: 50;
    color: #b9b9b9;
    transition: 0.3s ease-in-out;
  }

  .close:hover {
    color: #a0a0a0;
  }

  .popup-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 5;
    background-color: rgba(0, 0, 0, 0.5);
    width: 350px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: #009144;
    padding: 20px;
    border-radius: 10px;
  }

  .popup-container2 {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 5;
    background-color: rgba(0, 0, 0, 0.5);
    width: 350px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: #E90000;
    padding: 20px;
    border-radius: 10px;
  }

  .popup-container3 {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 5;
    background-color: rgba(0, 0, 0, 0.5);
    width: 450px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: #ffffff;
    padding: 30px;
    border-radius: 10px;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translate(-50%, -45%);
    }

    100% {
      opacity: 1;
      transform: translate(-50%, -50%);
    }
  }

  .validateT {
    color: #FCFCFC;
    opacity: 1;
    text-align: center;
    font-weight: 500;
    margin-top: 20px;
    font-size: 15px;
    text-transform: uppercase;
    font-weight: bold;
  }

  .errorT {
    color: #FCFCFC;
    opacity: 1;
    text-align: center;
    font-weight: 500;
    margin-top: 10px;
    font-size: 17px;
    font-weight: bold;
    text-transform: uppercase;
  }

  .editTPopUp {
    text-align: left;
    color: #262626;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px;
  }

  .validateInputDiv {
    flex-flow: column nowrap;
    display: flex;
    margin-bottom: 20px;
    margin-top: 30px;
  }

  .validateInputDiv input {
    border: 2px solid rgba(255, 255, 255, 0.726);
    color: white;
    opacity: 1;
    margin-bottom: 10px;
    border-radius: 7px;
    background-color: #36363600;
    padding: 5px;
    padding-left: 15px;
    outline: none;
  }


  .validateInputDiv2 {
    flex-flow: column nowrap;
    display: flex;
    margin-bottom: 20px;
    margin-top: 30px;
  }

  .validateInputDiv2 input {
    border: 2px solid rgba(255, 255, 255, 0.726);
    color: rgba(44, 44, 44, 0);
    opacity: 1;
    margin-bottom: 10px;
    background-color: #36363600;
    border-bottom: 1px solid #3f3f3f54;
    outline: none;
    padding-bottom: 7px;
    color: #191919;
  }

  .validateInputDiv2 input::placeholder {
    color: #191919;
  }

  .validate {
    background-color: white;
    color: black;
    border-radius: 10px;
    border: none;
    padding: 7px;
    font-weight: bold;
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 120px;
  }

  .validate:hover {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transition: 0.3s ease-in-out;
  }

  .validate:active {
    background: #cccccc;
    transform: scale(0.98);
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
  }

  .errorFlexBtns {
    display: flex;
    justify-content: center;
    margin-top: 35px;
  }

  .errorP {
    color: white;
    opacity: 1;
    text-align: center;
    margin-top: 10px;
  }

  .saveEdits {
    background-color: #AD5EFF;
    color: rgb(255, 255, 255);
    border-radius: 10px;
    border: none;
    padding: 7px;
    display: block;
    width: 120px;
    margin-left: auto;
    transition: 0.3s ease-in-out;
  }

  .saveEdits:hover {
    background: #8c40dd 0% 0% no-repeat padding-box;
  }

  .saveEdits:active {
    background: #7a32c4;
    transform: scale(0.98);
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
  }

  .softCapTargetPercentage {
    background-color: #009144;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {

  .adminInputs {
    width: 50%;
    margin-top: 40px;
  }

  .proceed {
    background-color: #8c40dd;
    color: white;
    margin: auto;
    display: block;
    border: none;
    padding: 10px;
    border-radius: 100px;
    width: 250px;
    transition: 0.3s ease-in-out;
  }
  
  #confirmPledgeForm {
    margin-top: 30px;
  }

  .admin-panel {
    background-color: #2c2c2c;
    border: 1px solid #b9b9b9;
    border-radius: 8px;
    padding: 20px;
    width: 400px;
  }

  .admin-panel h2 {
    color: #AD5EFF;
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
  }

  .form-group {
    margin-bottom: 15px;
  }

  .form-group label {
    display: block;
    font-size: 14px;
    margin-bottom: 10px;
    text-align: left;
  }

  .form-group input {
    width: 400px;
    padding: 8px;
    border: 1px solid #b9b9b9;
    border-radius: 4px;
    background-color: #1a1a1a;
    color: #b9b9b9;
  }

  .form-group input::placeholder {
    color: #616161;
  }

  .form-group input[type="number"] {
    -moz-appearance: textfield;
  }

  .form-group input[type="number"]::-webkit-outer-spin-button,
  .form-group input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .form-group button {
    width: 400px;
    padding: 10px;
    border: none;
    border-radius: 4px;
    background-color: #AD5EFF;
    color: white;
    font-size: 16px;
    cursor: pointer;
  }

  .form-group button:hover {
    background-color: #962EEB;
  }

  .responseSuccess {
    font-weight: 700;
    text-align: center;
  }

  .review {
    color: #5fb619;
    font-weight: 500;
    text-align: center;
  }

  .review2 {
    color: red;
    font-weight: 500;
    text-align: center;
  }

  .popup-containerMain3 {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100vh;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .popup-container3 {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.5);
    width: 30%;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }


  .success {
    background-color: #ffffff;
    border-color: #d6e9c6;
  }

  .close2 {
    right: 5px;
    margin-top: 5px;
    cursor: pointer;
    font-size: 24px;
    z-index: 50;
    color: #b9b9b9;
    transition: 0.3s ease-in-out;
    text-align: right;
  }

  .close2:hover {
    color: #a0a0a0;
  }


  .regsiterFlex {
    display: flex;
    justify-content: space-evenly;
  }

  /* Overlay for dimming the background */
  .register-overlay,
  .login-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.63);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
  }

  .register-popup {
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    padding-top: 0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    max-width: 90%;
    width: 90%;
    text-align: center;
  }

  .leftSectionWidth {
    width: 50%;
  }

  .rightSectionWidth {
    width: 30%;
  }

  /* General pop-up styling */
  .login-popup {
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    padding-top: 0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    max-width: 400px;
    width: 90%;
    text-align: center;
  }

  /* Header styling */
  .register-popup h2,
  .login-popup h2 {
    font-size: 26px;
    margin-bottom: 20px;
    font-weight: bold;
  }

  /* Input fields styling */
  .register-popup input[type="text"],
  .register-popup input[type="email"],
  .register-popup input[type="password"],
  .login-popup input[type="text"],
  .login-popup input[type="password"] {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
    color: #262626;
    outline-color: #8c40dd;
  }

  .optionCountries {
    outline-color: #8c40dd;
    text-align: left;
  }

  .register-popup input::placeholder {
    color: #5f5f5f;
  }

  .login-popup input::placeholder {
    color: #5f5f5f;
  }

  /* Button styling */
  .register-popup .register-btn,
  .login-popup .login-btn {
    background-color: #AD5EFF;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 20px;
    transition: 0.3s ease-in-out;
  }

  .register-popup .register-btn,
  .login-popup .login-btn:hover {
    background: #8c40dd 0% 0% no-repeat padding-box;
  }

  .register-popup .register-btn,
  .login-popup .login-btn:active {
    background: #7a32c4;
    /* Darken the color on active */
    transform: scale(0.98);
    /* Slightly reduce the size to simulate a press */
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
    /* Add an inset shadow for a pressed effect */
  }

  /* Cancel and forgot password link styling */
  .register-popup .cancel-btn,
  .login-popup .forgot-password {
    color: #666666;
    margin-top: 10px;
    display: inline-block;
    cursor: pointer;
    font-size: 14px;
    margin-left: 10px;
    text-decoration: underline;
  }

  #remember-me2 {
    margin-left: 5px;
  }

  .accountCreate {
    margin-top: 25px;
    color: #363636;
    font-size: 14px;
  }

  .rh {
    font-weight: 600;
    color: #AD5EFF;
    text-decoration: underline;
    cursor: pointer;
    transition: 0.3s ease-in-out;
  }

  .rh:hover {
    color: #c666fd;
  }

  .onlyMob {
    display: none;
  }

  .allWrap {
    background-color: #000002;
    height: 100vh;
  }

  .light {
    background-color: #000002;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    height: 100vh;
    height: max-content;
    justify-content: center;
  }

  .connectT {
    color: white;
    background-color: #AD5EFF;
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    text-decoration: underline;
    padding: 2px;
    font-weight: 500;
    cursor: pointer;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    border-radius: 100px;
    transition: 0.3s ease-in-out;
  }

  .connectT:hover {
    background: #8c40dd 0% 0% no-repeat padding-box;
  }

  .connectT:active {
    background: #7a32c4;
    /* Darken the color on active */
    transform: scale(0.98);
    /* Slightly reduce the size to simulate a press */
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
    /* Add an inset shadow for a pressed effect */
  }

  .leftSection {
    border: 0.3px solid #FCFCFC;
    border-radius: 10px;
    width: 300px;
    height: 90vh;
    height: max-content;
    margin-top: 15px;
    margin-left: 15px;
    position: sticky;
    top: 20px;
    margin-bottom: 20px;
  }

  .logo {
    width: 100px;
  }

  .logoDiv {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .section1 {
    border-top: #fcfcfc71 0.3px solid;
  }


  .pagesDiv {
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    margin-top: 6vh;
    margin-bottom: 7vh;
    color: white;
  }

  .pagesDiv div {
    color: white;
    font-size: 14px;
    margin-top: auto;
    margin-bottom: auto;
    margin: 10px;
    cursor: pointer;
    transition: 0.3s ease-in-out;
  }


  .pagesDiv-2 {
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    margin-top: 6vh;
    margin-bottom: 7vh;
    color: #727070;
  }

  .pagesDiv-2 div {
    color: #727070;
    font-size: 14px;
    margin-top: auto;
    margin-bottom: auto;
    margin: 10px;
    cursor: pointer;
    transition: 0.3s ease-in-out;
  }


  .headerNav {
    color: white;
  }

  .pagesDiv div:hover {
    color: #919191;
  }

  .pagesDiv img {
    width: 15px;
    height: 15px;
    margin-top: -5px;
    margin-right: 12px;
  }

  .pagesDiv-2 img {
    width: 15px;
    height: 15px;
    margin-top: -5px;
    margin-right: 12px;
  }

  .section3Headers {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .section3HeadersMain {
    border: 1px solid #AD5EFF;
    width: fit-content;
    padding: 10px;
    border-radius: 7px;
    width: 100%;
    cursor: pointer;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .amountSection {
    margin-top: 10px;
  }

  .ghutAmount2 {
    font-size: 20px;
    font-weight: 100;
    color: rgb(228, 228, 228);
    width: 150px;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
    margin-left: 15px;
  }

  .maticAmount {
    font-size: 18px;
    font-weight: 100;
    color: rgb(228, 228, 228);
    margin-left: 15px;
  }

  #amountHL {
    color: white;
    font-weight: 500;
  }

  .section3Headers img {
    width: 15px;
    height: 15px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .section3Headers div {
    color: white;
    font-size: 14px;
  }

  .grayTxt {
    color: #727070;
    transition: 0.3s ease-in-out;
  }

  .grayTxt:hover {
    color: #bbbbbb;
  }

  .section4 {
    margin-top: 10vh;
  }

  .title1Rsection {
    font-size: 23px;
  }

  .page1Main {
    display: flex;
    justify-content: space-between;
  }

  .mainT {
    color: #727070;
    margin-bottom: 15px;
    text-align: left;

  }

  .rightSection {
    margin-right: auto;
    margin-left: auto;
    width: 100%;
    padding: 40px;
  }

  .rightSection--2 {
    display: flex;
    justify-content: space-between;
  }

  .rightSection-1 {
    width: 100%;
  }

  .rightSection-2 {
    width: 30%;
    margin-right: auto;
    display: none;
  }

  .rightSection-2-2 {
    width: 90%;
    margin-left: 1%;
  }

  .title1Rsection {
    text-align: left;
    color: #FCFCFC;
  }

  .title2Rsection {
    color: #AD5EFF;
    font-size: 20px;
  }

  .details {
    font-size: 16px;
    margin-top: 10px;
  }

  #de1 {
    color: white;
    font-weight: 500;
  }

  #de2 {
    color: rgb(173, 173, 173);
    font-weight: 500;
  }

  #de3 {
    color: rgb(128, 128, 128);
    font-weight: 500;
  }

  .note {
    font-size: 16px;
    color: #727070ec;
    margin-top: 10px;
  }

  .flexBox {
    display: flex;
    justify-content: center;
    margin-top: 50px;
  }

  .purpleBox {
    background-color: #AD5EFF;
    border-radius: 6px;
    padding: 15px;
    width: 95%;
  }

  .blackBox {
    background-color: rgb(0, 0, 0);
    width: 95%;
    margin-top: auto;
    margin-bottom: auto;
    color: white;
    width: 90%;
    padding: 10px;
  }

  .boxT1 {
    font-size: 18px;
    font-weight: 700;
  }

  .boxT2 {
    font-size: 23px;
    font-weight: bolder;
  }

  .boxT3 {
    font-size: 16px;
    font-weight: 400;
    margin-top: 10px;
  }

  .giftSection {
    font-size: 14px;
    margin-top: 17px;
    display: flex;
    justify-content: center;
  }

  .giftSection img {
    width: 18px;
    height: 18px;
    margin-right: 10px;
  }

  .giftSectionP1 {
    font-weight: bold;
  }

  .underline {
    text-decoration: underline;
    cursor: pointer;
    transition: 0.3s ease-in-out;
  }

  .underline:hover {
    color: black;
  }

  .bbTitle {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 10px;

  }

  #bbTitle2 {
    font-weight: 300;
  }

  .bbTitle3 {
    font-size: 14px;
  }

  .box2Main {
    width: 85%;
    margin-left: auto;
    margin-right: auto;
    text-align: justify;
  }

  .imgs2_1 {
    width: 80px;
    margin-right: 10px;
  }

  .imgs2_2 {
    width: 40px;
    margin-left: 10px;

  }

  .imgs2BB {
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
    justify-content: center;
  }

  .line {
    width: 100%;
    height: 1px;
    background-color: rgba(255, 255, 255, 0.26);
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .flexBox2T {
    font-size: 16px;
    color: #AD5EFF;
    font-weight: 500;
    margin-bottom: 30px;
  }

  .flexBox3T {
    font-size: 22px;
    color: #AD5EFF;
    font-weight: 500;
    font-weight: 300;
  }

  .flexBox4T {
    font-size: 25px;
    color: #ffffff;
    font-weight: 300;
    margin-bottom: 30px;
    width: 100%;
  }

  .amountBox1 {
    border: 0.5px solid #fcfcfc6e;
    opacity: 1;
    border-radius: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 50%;
    padding: 12px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

  }

  .amountBox2 {
    width: 50%;
    display: flex;
    justify-content: center;
    /* Center horizontally */
    align-items: center;
    /* Center vertically */
    border: 0.5px solid #fcfcfc6e;
    border-left: 0.5px solid #fcfcfc00;
    opacity: 1;
    border-radius: 5px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

  }

  .amountBoxMain {
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
    color: white;
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .sendCoin {
    font-size: 14px;
    font-weight: 400;
    margin-top: -23px;
    background-color: black;
    width: fit-content;
    font-weight: 300;
  }

  .amount {
    font-size: 25px;
    font-weight: 400;
    text-align: center;
  }

  .amountBox button {
    background: var(--violeta) 0% 0% no-repeat padding-box;
    background: #AD5EFF 0% 0% no-repeat padding-box;
    border-radius: 5px;
    opacity: 1;
    border: none;
    color: white;
    width: 100%;
    padding: 5px;
    transition: 0.3s ease-in-out;
  }

  .amountBox button:hover {
    background: #8c40dd 0% 0% no-repeat padding-box;
  }

  .amountBox button:active {
    background: #7a32c4;
    /* Darken the color on active */
    transform: scale(0.98);
    /* Slightly reduce the size to simulate a press */
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
    /* Add an inset shadow for a pressed effect */
  }

  .usdtAmount {
    width: 30px;
    height: 30px;
  }

  .ghutAmount {
    width: 30px;
    height: 30px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .line2 {
    width: 8px;
    height: 100%;
    background-color: #727070;
  }

  .amount input {
    background-color: black;
    width: 100%;
    border: none;
    outline: none;
    color: white;
    text-align: center;
  }

  ::placeholder {
    color: white;
  }

  .progress-container {
    margin-top: 10px;
    width: 100%;
    background-color: white;
    border-radius: 100px;
  }

  .progress-bar {
    background-color: rgb(206, 206, 206);
    height: 20px;
    border-radius: 100px;
    padding: 11px;
    font-size: 14px;
  }

  .progress-container2 {
    margin-top: 10px;
    width: 100%;
    background-color: white;
    border-radius: 100px;

  }

  ._softCapTargetPercentage {
    background-color: #000000;
    border-radius: 100px;
    font-size: 12px;
    text-align: center;
    font-weight: bold;
    margin-top: -21px;
    color: white;
    border: 1px solid rgb(0, 0, 0);
  }

  .noteMm {
    text-align: center;
    color: white;
    margin-top: 25px;
  }

  .installMm {
    text-align: center;
    color: white;
    text-decoration: underline;
    transition: 0.3s ease-in-out;
  }

  .installMm:hover {
    cursor: pointer;
    color: #727070;
  }

  .transaction-list-main {
    background-color: #141414;
    /* Dark background */
    border-radius: 10px;
    padding: 20px;
    width: 100%;
    border: 1px solid rgba(37, 37, 37, 0.068);
  }

  .uploadMain {
    background-color: #141414;
    /* Dark background */
    border-radius: 10px;
    padding: 10px;
    width: 95%;
    border: 1px solid rgba(37, 37, 37, 0.068);
  }

  .transaction-list {
    display: flex;
    width: 97%;
    justify-content: space-between;
    margin: 20px;
    margin-top: 25px;
    margin-bottom: 25px;
    margin-left: auto;
    margin-right: auto;
  }

  .list1 {
    display: flex;
  }

  .list1 img {
    width: 25px;
    height: 25px;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 7px;
  }

  .list1Con {
    color: #8c40dd;
    font-weight: 500;
    font-size: 14px;
  }

  .list1Con2 {
    color: #616161;
    font-weight: 300;
    font-size: 14px;
  }

  .listConMain {
    margin-top: auto;
    margin-bottom: auto;
  }

  .listConMain2 {
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    color: #c7c7c7;
    margin-top: auto;
    margin-bottom: auto;
  }

  .listConMain2-2 {
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 10px;
    font-size: 15px;
  }

  .listConMain3 {
    display: flex;
    background-color: #262626;
    color: #c7c7c7;
    width: 100px;
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 10px;
    font-size: 14px;
  }

  .listConMain3 img {
    width: 15px;
    height: 15px;
    margin-top: auto;
    margin-bottom: auto;
    cursor: pointer;
    transition: transform .2s;
  }

  .listConMain3 img:hover {
    transform: scale(1.1);
  }

  .listBtn:hover {
    background: #8c40dd 0% 0% no-repeat padding-box;
  }

  .listBtn:active {
    background: #7a32c4;
    /* Darken the color on active */
    transform: scale(0.98);
    /* Slightly reduce the size to simulate a press */
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
    /* Add an inset shadow for a pressed effect */
  }

  .list1Con3 {
    color: #616161;
    font-size: 14px;
  }

  .listBtn {
    background-color: #AD5EFF;
    border: none;
    border-radius: 7px;
    color: white;
    font-weight: bold;
    width: 60px;
    padding: 5px;
    transition: 0.3s ease-in-out;
    font-size: 13px;

  }

  .listT {
    font-weight: 700;
    color: white;
    width: 100%;
    margin-left: 2%;
  }

  .line3 {
    width: 100%;
    height: 2px;
    background-color: rgba(255, 255, 255, 0.418);
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .listNote {
    text-align: center;
    color: #AD5EFF;
    font-size: 12px;
    margin-top: 5%;
  }

  .profile {
    display: flex;
    justify-content: space-between;
    margin-top: 5%;
  }

  .upload img {
    width: 150px;
  }

  .upload div {
    color: #AD5EFF;
    text-align: center;
  }

  label {
    color: #AD5EFF;
    text-align: center;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    flex-flow: column nowrap;
  }

  label:hover {
    color: #bf87fc;
  }

  .upload2 {
    width: 70%;
  }

  .editInputArea {
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;
    margin-top: 15px;
    border-bottom: #363636 1px solid;
    margin-left: 10px;
  }

  .editHere {
    display: flex;
  }

  .editHere div {
    margin-top: auto;
    margin-bottom: auto;
    color: #AD5EFF;
    margin-left: 10px;
    font-size: 14px;
    cursor: pointer;
  }

  .editHere img {
    width: 18px;
    height: 18px;
    margin-top: auto;
    margin-bottom: auto;
    cursor: pointer;
  }

  .editInputArea input {
    background-color: #00000200;
    width: 100%;
    border: none;
    outline: none;
    font-size: 16px;
    color: #dadada;
  }

  .editInputArea input::placeholder {
    color: #616161;
  }

  .editT {
    color: #616161;
    font-size: 12px;
    font-weight: bolder;
  }

  .grid {
    width: 8%;
    z-index: 5;
    position: fixed;
    right: 0;
    bottom: 0;
  }

  .popup-containerMain {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 5;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100vh;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    animation: fadeIn 0.4s ease-in-out forwards;

  }

  .close {
    position: absolute;
    top: 5px;
    right: 10px;
    cursor: pointer;
    font-size: 22px;
    z-index: 50;
    color: #b9b9b9;
    transition: 0.3s ease-in-out;
  }

  .close:hover {
    color: #a0a0a0;
  }

  .popup-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 5;
    background-color: rgba(0, 0, 0, 0.5);
    width: 350px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: #009144;
    padding: 20px;
    border-radius: 10px;
  }

  .popup-container2 {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 5;
    background-color: rgba(0, 0, 0, 0.5);
    width: 350px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: #E90000;
    padding: 20px;
    border-radius: 10px;
  }

  .popup-container3 {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 5;
    background-color: rgba(0, 0, 0, 0.5);
    width: 450px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: #ffffff;
    padding: 30px;
    border-radius: 10px;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translate(-50%, -45%);
    }

    100% {
      opacity: 1;
      transform: translate(-50%, -50%);
    }
  }

  .validateT {
    color: #FCFCFC;
    opacity: 1;
    text-align: center;
    font-weight: 500;
    margin-top: 20px;
    font-size: 15px;
    text-transform: uppercase;
    font-weight: bold;
  }

  .errorT {
    color: #FCFCFC;
    opacity: 1;
    text-align: center;
    font-weight: 500;
    margin-top: 10px;
    font-size: 17px;
    font-weight: bold;
    text-transform: uppercase;
  }

  .editTPopUp {
    text-align: left;
    color: #262626;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px;
  }

  .validateInputDiv {
    flex-flow: column nowrap;
    display: flex;
    margin-bottom: 20px;
    margin-top: 30px;
  }

  .validateInputDiv input {
    border: 2px solid rgba(255, 255, 255, 0.726);
    color: white;
    opacity: 1;
    margin-bottom: 10px;
    border-radius: 7px;
    background-color: #36363600;
    padding: 5px;
    padding-left: 15px;
    outline: none;
  }


  .validateInputDiv2 {
    flex-flow: column nowrap;
    display: flex;
    margin-bottom: 20px;
    margin-top: 30px;
  }

  .validateInputDiv2 input {
    border: 2px solid rgba(255, 255, 255, 0.726);
    color: rgba(44, 44, 44, 0);
    opacity: 1;
    margin-bottom: 10px;
    background-color: #36363600;
    border-bottom: 1px solid #3f3f3f54;
    outline: none;
    padding-bottom: 7px;
    color: #191919;
  }

  .validateInputDiv2 input::placeholder {
    color: #191919;
  }

  .validate {
    background-color: white;
    color: black;
    border-radius: 10px;
    border: none;
    padding: 7px;
    font-weight: bold;
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 120px;
  }

  .validate:hover {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transition: 0.3s ease-in-out;
  }

  .validate:active {
    background: #cccccc;
    transform: scale(0.98);
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
  }

  .errorFlexBtns {
    display: flex;
    justify-content: center;
    margin-top: 35px;
  }

  .errorP {
    color: white;
    opacity: 1;
    text-align: center;
    margin-top: 10px;
  }

  .saveEdits {
    background-color: #AD5EFF;
    color: rgb(255, 255, 255);
    border-radius: 10px;
    border: none;
    padding: 7px;
    display: block;
    width: 120px;
    margin-left: auto;
    transition: 0.3s ease-in-out;
  }

  .saveEdits:hover {
    background: #8c40dd 0% 0% no-repeat padding-box;
  }

  .saveEdits:active {
    background: #7a32c4;
    transform: scale(0.98);
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
  }

  .softCapTargetPercentage {
    background-color: #009144;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1919px) {

  .adminInputs {
    width: 50%;
    margin-top: 40px;
  }

  .proceed {
    background-color: #8c40dd;
    color: white;
    margin: auto;
    display: block;
    border: none;
    padding: 10px;
    border-radius: 100px;
    width: 250px;
    transition: 0.3s ease-in-out;
  }
  
  #confirmPledgeForm {
    margin-top: 30px;
  }

  .admin-panel {
    background-color: #2c2c2c;
    border: 1px solid #b9b9b9;
    border-radius: 8px;
    padding: 20px;
    width: 400px;
  }

  .admin-panel h2 {
    color: #AD5EFF;
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
  }

  .form-group {
    margin-bottom: 15px;
  }

  .form-group label {
    display: block;
    font-size: 14px;
    margin-bottom: 10px;
    text-align: left;
  }

  .form-group input {
    width: 400px;
    padding: 8px;
    border: 1px solid #b9b9b9;
    border-radius: 4px;
    background-color: #1a1a1a;
    color: #b9b9b9;
  }

  .form-group input::placeholder {
    color: #616161;
  }

  .form-group input[type="number"] {
    -moz-appearance: textfield;
  }

  .form-group input[type="number"]::-webkit-outer-spin-button,
  .form-group input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .form-group button {
    width: 400px;
    padding: 10px;
    border: none;
    border-radius: 4px;
    background-color: #AD5EFF;
    color: white;
    font-size: 16px;
    cursor: pointer;
  }

  .form-group button:hover {
    background-color: #962EEB;
  }

  .responseSuccess {
    font-weight: 700;
    text-align: center;
  }

  .review {
    color: #5fb619;
    font-weight: 500;
    text-align: center;
  }

  .review2 {
    color: red;
    font-weight: 500;
    text-align: center;
  }

  .popup-containerMain3 {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100vh;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .popup-container3 {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.5);
    width: 30%;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }


  .success {
    background-color: #ffffff;
    border-color: #d6e9c6;
  }

  .close2 {
    right: 5px;
    margin-top: 5px;
    cursor: pointer;
    font-size: 24px;
    z-index: 50;
    color: #b9b9b9;
    transition: 0.3s ease-in-out;
    text-align: right;
  }

  .close2:hover {
    color: #a0a0a0;
  }


  .regsiterFlex {
    display: flex;
    justify-content: space-evenly;
  }

  /* Overlay for dimming the background */
  .register-overlay,
  .login-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.63);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
  }

  .register-popup {
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    padding-top: 0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    max-width: 90%;
    width: 90%;
    text-align: center;
  }

  .leftSectionWidth {
    width: 50%;
  }

  .rightSectionWidth {
    width: 30%;
  }

  /* General pop-up styling */
  .login-popup {
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    padding-top: 0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    max-width: 400px;
    width: 90%;
    text-align: center;
  }

  /* Header styling */
  .register-popup h2,
  .login-popup h2 {
    font-size: 26px;
    margin-bottom: 20px;
    font-weight: bold;
  }

  /* Input fields styling */
  .register-popup input[type="text"],
  .register-popup input[type="email"],
  .register-popup input[type="password"],
  .login-popup input[type="text"],
  .login-popup input[type="password"] {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
    color: #262626;
    outline-color: #8c40dd;
  }

  .optionCountries {
    outline-color: #8c40dd;
    text-align: left;
  }

  .register-popup input::placeholder {
    color: #5f5f5f;
  }

  .login-popup input::placeholder {
    color: #5f5f5f;
  }

  /* Button styling */
  .register-popup .register-btn,
  .login-popup .login-btn {
    background-color: #AD5EFF;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 20px;
    transition: 0.3s ease-in-out;
  }

  .register-popup .register-btn,
  .login-popup .login-btn:hover {
    background: #8c40dd 0% 0% no-repeat padding-box;
  }

  .register-popup .register-btn,
  .login-popup .login-btn:active {
    background: #7a32c4;
    /* Darken the color on active */
    transform: scale(0.98);
    /* Slightly reduce the size to simulate a press */
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
    /* Add an inset shadow for a pressed effect */
  }

  /* Cancel and forgot password link styling */
  .register-popup .cancel-btn,
  .login-popup .forgot-password {
    color: #666666;
    margin-top: 10px;
    display: inline-block;
    cursor: pointer;
    font-size: 14px;
    margin-left: 10px;
    text-decoration: underline;
  }

  #remember-me2 {
    margin-left: 5px;
  }

  .accountCreate {
    margin-top: 25px;
    color: #363636;
    font-size: 14px;
  }

  .rh {
    font-weight: 600;
    color: #AD5EFF;
    text-decoration: underline;
    cursor: pointer;
    transition: 0.3s ease-in-out;
  }

  .rh:hover {
    color: #c666fd;
  }

  .onlyMob {
    display: none;
  }

  .allWrap {
    background-color: #000002;
    height: 100vh;
  }


  .light {
    background-color: #000002;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    height: 100vh;
    height: max-content;
    justify-content: center;
  }


  .connectT {
    color: white;
    background-color: #AD5EFF;
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    text-decoration: underline;
    padding: 2px;
    font-weight: 500;
    cursor: pointer;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    border-radius: 100px;
    transition: 0.3s ease-in-out;
  }

  .connectT:hover {
    background: #8c40dd 0% 0% no-repeat padding-box;
  }

  .connectT:active {
    background: #7a32c4;
    /* Darken the color on active */
    transform: scale(0.98);
    /* Slightly reduce the size to simulate a press */
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
    /* Add an inset shadow for a pressed effect */
  }

  .leftSection {
    border: 0.3px solid #FCFCFC;
    border-radius: 10px;
    width: 20%;
    height: 90vh;
    height: max-content;
    margin-top: 15px;
    margin-left: 15px;
    position: sticky;
    top: 20px;
    margin-bottom: 20px;
  }

  .logo {
    width: 100px;
  }

  .logoDiv {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .section1 {
    border-top: #fcfcfc71 0.3px solid;
  }


  .pagesDiv {
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    margin-top: 6vh;
    margin-bottom: 7vh;
    color: white;
  }

  .pagesDiv div {
    color: white;
    font-size: 14px;
    margin-top: auto;
    margin-bottom: auto;
    margin: 10px;
    cursor: pointer;
    transition: 0.3s ease-in-out;
  }


  .pagesDiv-2 {
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    margin-top: 6vh;
    margin-bottom: 7vh;
    color: #727070;
  }

  .pagesDiv-2 div {
    color: #727070;
    font-size: 14px;
    margin-top: auto;
    margin-bottom: auto;
    margin: 10px;
    cursor: pointer;
    transition: 0.3s ease-in-out;
  }


  .headerNav {
    color: white;
  }

  .pagesDiv div:hover {
    color: #919191;
  }

  .pagesDiv img {
    width: 15px;
    height: 15px;
    margin-top: -5px;
    margin-right: 12px;
  }

  .pagesDiv-2 img {
    width: 15px;
    height: 15px;
    margin-top: -5px;
    margin-right: 12px;
  }

  .section3Headers {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .section3HeadersMain {
    border: 1px solid #AD5EFF;
    width: fit-content;
    padding: 10px;
    border-radius: 7px;
    width: 100%;
    cursor: pointer;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .amountSection {
    margin-top: 10px;
  }

  .ghutAmount2 {
    font-size: 20px;
    font-weight: 100;
    color: rgb(228, 228, 228);
    width: 150px;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
    margin-left: 15px;
  }

  .maticAmount {
    font-size: 18px;
    font-weight: 100;
    color: rgb(228, 228, 228);
    margin-left: 15px;
  }

  #amountHL {
    color: white;
    font-weight: 500;
  }

  .section3Headers img {
    width: 15px;
    height: 15px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .section3Headers div {
    color: white;
    font-size: 14px;
  }

  .grayTxt {
    color: #727070;
    transition: 0.3s ease-in-out;
  }

  .grayTxt:hover {
    color: #bbbbbb;
  }

  .section4 {
    margin-top: 10vh;
  }

  .title1Rsection {
    font-size: 23px;
  }

  .page1Main {
    display: flex;
    justify-content: space-between;
  }

  .mainT {
    color: #727070;
    margin-bottom: 15px;
    text-align: left;
  }

  .rightSection {
    margin-right: auto;
    margin-left: auto;
    width: 100%;
    padding: 40px;
  }

  .rightSection--2 {
    display: flex;
    justify-content: space-between;
  }

  .rightSection-1 {
    width: 65%;
  }

  .rightSection-2 {
    width: 30%;
    margin-right: auto;
  }

  .rightSection-2-2 {
    width: 30%;
    margin-left: 1%;
  }

  .title1Rsection {
    text-align: left;
    color: #FCFCFC;
  }

  .title2Rsection {
    color: #AD5EFF;
    font-size: 20px;
  }

  .details {
    font-size: 16px;
    margin-top: 10px;
  }

  #de1 {
    color: white;
    font-weight: 500;
  }

  #de2 {
    color: rgb(173, 173, 173);
    font-weight: 500;
  }

  #de3 {
    color: rgb(128, 128, 128);
    font-weight: 500;
  }

  .note {
    font-size: 16px;
    color: #727070ec;
    margin-top: 10px;
  }

  .flexBox {
    display: flex;
    justify-content: center;
    margin-top: 50px;
  }

  .purpleBox {
    background-color: #AD5EFF;
    border-radius: 6px;
    padding: 15px;
    width: 95%;
  }

  .blackBox {
    background-color: rgb(0, 0, 0);
    width: 95%;
    margin-top: auto;
    margin-bottom: auto;
    color: white;
    width: 90%;
    padding: 10px;
  }

  .boxT1 {
    font-size: 18px;
    font-weight: 700;
  }

  .boxT2 {
    font-size: 23px;
    font-weight: bolder;
  }

  .boxT3 {
    font-size: 16px;
    font-weight: 400;
    margin-top: 10px;
  }

  .giftSection {
    font-size: 14px;
    margin-top: 17px;
    display: flex;
    justify-content: center;
  }

  .giftSection img {
    width: 18px;
    height: 18px;
    margin-right: 10px;
  }

  .giftSectionP1 {
    font-weight: bold;
  }

  .underline {
    text-decoration: underline;
    cursor: pointer;
    transition: 0.3s ease-in-out;
  }

  .underline:hover {
    color: black;
  }

  .bbTitle {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 10px;

  }

  #bbTitle2 {
    font-weight: 300;
  }

  .bbTitle3 {
    font-size: 14px;
  }

  .box2Main {
    width: 85%;
    margin-left: auto;
    margin-right: auto;
    text-align: justify;
  }

  .imgs2_1 {
    width: 80px;
    margin-right: 10px;
  }

  .imgs2_2 {
    width: 40px;
    margin-left: 10px;

  }

  .imgs2BB {
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
    justify-content: center;
  }

  .line {
    width: 100%;
    height: 1px;
    background-color: rgba(255, 255, 255, 0.26);
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .flexBox2T {
    font-size: 16px;
    color: #AD5EFF;
    font-weight: 500;
    margin-bottom: 30px;
  }

  .flexBox3T {
    font-size: 22px;
    color: #AD5EFF;
    font-weight: 500;
    font-weight: 300;
  }

  .flexBox4T {
    font-size: 25px;
    color: #ffffff;
    font-weight: 300;
    margin-bottom: 30px;
    width: 100%;
  }

  .amountBox1 {
    border: 0.5px solid #fcfcfc6e;
    opacity: 1;
    border-radius: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 50%;
    padding: 12px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

  }

  .amountBox2 {
    width: 50%;
    display: flex;
    justify-content: center;
    /* Center horizontally */
    align-items: center;
    /* Center vertically */
    border: 0.5px solid #fcfcfc6e;
    border-left: 0.5px solid #fcfcfc00;
    opacity: 1;
    border-radius: 5px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

  }

  .amountBoxMain {
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
    color: white;
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .sendCoin {
    font-size: 14px;
    font-weight: 400;
    margin-top: -23px;
    background-color: black;
    width: fit-content;
    font-weight: 300;
  }

  .amount {
    font-size: 25px;
    font-weight: 400;
    text-align: center;
  }

  .amountBox button {
    background: var(--violeta) 0% 0% no-repeat padding-box;
    background: #AD5EFF 0% 0% no-repeat padding-box;
    border-radius: 5px;
    opacity: 1;
    border: none;
    color: white;
    width: 100%;
    padding: 5px;
    transition: 0.3s ease-in-out;
  }

  .amountBox button:hover {
    background: #8c40dd 0% 0% no-repeat padding-box;
  }

  .amountBox button:active {
    background: #7a32c4;
    /* Darken the color on active */
    transform: scale(0.98);
    /* Slightly reduce the size to simulate a press */
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
    /* Add an inset shadow for a pressed effect */
  }

  .usdtAmount {
    width: 30px;
    height: 30px;
  }

  .ghutAmount {
    width: 30px;
    height: 30px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .line2 {
    width: 8px;
    height: 100%;
    background-color: #727070;
  }

  .amount input {
    background-color: black;
    width: 100%;
    border: none;
    outline: none;
    color: white;
    text-align: center;
  }

  ::placeholder {
    color: white;
  }

  .progress-container {
    margin-top: 10px;
    width: 100%;
    background-color: white;
    border-radius: 100px;
  }

  .progress-bar {
    background-color: rgb(206, 206, 206);
    height: 20px;
    border-radius: 100px;
    padding: 11px;
    font-size: 14px;
  }

  .progress-container2 {
    margin-top: 10px;
    width: 100%;
    background-color: white;
    border-radius: 100px;

  }

  ._softCapTargetPercentage {
    background-color: #000000;
    border-radius: 100px;
    font-size: 12px;
    text-align: center;
    font-weight: bold;
    margin-top: -21px;
    color: white;
    border: 1px solid rgb(0, 0, 0);
  }

  .noteMm {
    text-align: center;
    color: white;
    margin-top: 25px;
  }

  .installMm {
    text-align: center;
    color: white;
    text-decoration: underline;
    transition: 0.3s ease-in-out;
  }

  .installMm:hover {
    cursor: pointer;
    color: #727070;
  }

  .transaction-list-main {
    background-color: #141414;
    /* Dark background */
    border-radius: 10px;
    padding: 20px;
    width: 92%;
    border: 1px solid rgba(37, 37, 37, 0.068);
  }

  .uploadMain {
    background-color: #141414;
    /* Dark background */
    border-radius: 10px;
    padding: 20px;
    width: 80%;
    border: 1px solid rgba(37, 37, 37, 0.068);
  }

  .transaction-list {
    display: flex;
    width: 97%;
    justify-content: space-between;
    margin: 20px;
    margin-top: 25px;
    margin-bottom: 25px;
    margin-left: auto;
    margin-right: auto;
  }

  .list1 {
    display: flex;
  }

  .list1 img {
    width: 28px;
    height: 28px;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 7px;
  }

  .list1Con {
    color: #8c40dd;
    font-weight: 500;
    font-size: 16px;
  }

  .list1Con2 {
    color: #616161;
    font-weight: 300;
    font-size: 15px;
  }

  .listConMain {
    margin-top: auto;
    margin-bottom: auto;
  }

  .listConMain2 {
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    color: #c7c7c7;
    margin-top: auto;
    margin-bottom: auto;
  }

  .listConMain2-2 {
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 10px;
    font-size: 15px;
  }

  .listConMain3 {
    display: flex;
    background-color: #262626;
    color: #c7c7c7;
    width: 150px;
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 10px;
  }

  .listConMain3 img {
    width: 18px;
    height: 18px;
    margin-top: auto;
    margin-bottom: auto;
    cursor: pointer;
    transition: transform .2s;
  }

  .listConMain3 img:hover {
    transform: scale(1.1);
  }

  .listBtn:hover {
    background: #8c40dd 0% 0% no-repeat padding-box;
  }

  .listBtn:active {
    background: #7a32c4;
    /* Darken the color on active */
    transform: scale(0.98);
    /* Slightly reduce the size to simulate a press */
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
    /* Add an inset shadow for a pressed effect */
  }

  .list1Con3 {
    color: #616161;
    font-size: 14px;
  }

  .listBtn {
    background-color: #AD5EFF;
    border: none;
    border-radius: 7px;
    color: white;
    font-weight: bold;
    width: 100px;
    padding: 5px;
    transition: 0.3s ease-in-out;
    font-size: 14px;

  }

  .listT {
    font-weight: 700;
    color: white;
    width: 100%;
    margin-left: 2%;
  }

  .line3 {
    width: 100%;
    height: 2px;
    background-color: rgba(255, 255, 255, 0.418);
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .listNote {
    text-align: center;
    color: #AD5EFF;
    font-size: 12px;
    margin-top: 5%;
  }

  .profile {
    display: flex;
    justify-content: space-between;
    margin-top: 5%;
  }

  .upload img {
    width: 150px;
  }

  .upload div {
    color: #AD5EFF;
    text-align: center;
  }

  label {
    color: #AD5EFF;
    text-align: center;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    flex-flow: column nowrap;
  }

  label:hover {
    color: #bf87fc;
  }

  .upload2 {
    width: 72%;
  }

  .editInputArea {
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;
    margin-top: 15px;
    border-bottom: #363636 1px solid;
    margin-left: 10px;
  }

  .editHere {
    display: flex;
  }

  .editHere div {
    margin-top: auto;
    margin-bottom: auto;
    color: #AD5EFF;
    margin-left: 10px;
    font-size: 14px;
    cursor: pointer;
  }

  .editHere img {
    width: 18px;
    height: 18px;
    margin-top: auto;
    margin-bottom: auto;
    cursor: pointer;
  }

  .editInputArea input {
    background-color: #00000200;
    width: 100%;
    border: none;
    outline: none;
    font-size: 16px;
    color: #dadada;
  }

  .editInputArea input::placeholder {
    color: #616161;
  }

  .editT {
    color: #616161;
    font-size: 12px;
    font-weight: bolder;
  }

  .grid {
    width: 8%;
    z-index: 5;
    position: fixed;
    right: 0;
    bottom: 0;
  }

  .popup-containerMain {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100vh;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    animation: fadeIn 0.4s ease-in-out forwards;

  }

  .close {
    position: absolute;
    top: 5px;
    right: 10px;
    cursor: pointer;
    font-size: 22px;
    z-index: 50;
    color: #b9b9b9;
    transition: 0.3s ease-in-out;
  }

  .close:hover {
    color: #a0a0a0;
  }

  .popup-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 5;
    background-color: rgba(0, 0, 0, 0.5);
    width: 350px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: #009144;
    padding: 20px;
    border-radius: 10px;
  }

  .popup-container2 {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 5;
    background-color: rgba(0, 0, 0, 0.5);
    width: 350px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: #E90000;
    padding: 20px;
    border-radius: 10px;
  }

  .popup-container3 {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 5;
    background-color: rgba(0, 0, 0, 0.5);
    width: 450px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: #ffffff;
    padding: 30px;
    border-radius: 10px;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translate(-50%, -45%);
    }

    100% {
      opacity: 1;
      transform: translate(-50%, -50%);
    }
  }

  .validateT {
    color: #FCFCFC;
    opacity: 1;
    text-align: center;
    font-weight: 500;
    margin-top: 20px;
    font-size: 15px;
    text-transform: uppercase;
    font-weight: bold;
  }

  .errorT {
    color: #FCFCFC;
    opacity: 1;
    text-align: center;
    font-weight: 500;
    margin-top: 10px;
    font-size: 17px;
    font-weight: bold;
    text-transform: uppercase;
  }

  .editTPopUp {
    text-align: left;
    color: #262626;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px;
  }

  .validateInputDiv {
    flex-flow: column nowrap;
    display: flex;
    margin-bottom: 20px;
    margin-top: 30px;
  }

  .validateInputDiv input {
    border: 2px solid rgba(255, 255, 255, 0.726);
    color: white;
    opacity: 1;
    margin-bottom: 10px;
    border-radius: 7px;
    background-color: #36363600;
    padding: 5px;
    padding-left: 15px;
    outline: none;
  }


  .validateInputDiv2 {
    flex-flow: column nowrap;
    display: flex;
    margin-bottom: 20px;
    margin-top: 30px;
    }

  .validateInputDiv2 input {
    border: 2px solid rgba(255, 255, 255, 0.726);
    color: rgba(44, 44, 44, 0);
    opacity: 1;
    margin-bottom: 10px;
    background-color: #36363600;
    border-bottom: 1px solid #3f3f3f54;
    outline: none;
    padding-bottom: 7px;
    color: #191919;
  }

  .validateInputDiv2 input::placeholder {
    color: #191919;
  }

  .validate {
    background-color: white;
    color: black;
    border-radius: 10px;
    border: none;
    padding: 7px;
    font-weight: bold;
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 120px;
  }

  .validate:hover {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transition: 0.3s ease-in-out;
  }

  .validate:active {
    background: #cccccc;
    transform: scale(0.98);
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
  }

  .errorFlexBtns {
    display: flex;
    justify-content: center;
    margin-top: 35px;
  }

  .errorP {
    color: white;
    opacity: 1;
    text-align: center;
    margin-top: 10px;
  }

  .saveEdits {
    background-color: #AD5EFF;
    color: rgb(255, 255, 255);
    border-radius: 10px;
    border: none;
    padding: 7px;
    display: block;
    width: 120px;
    margin-left: auto;
    transition: 0.3s ease-in-out;
  }

  .saveEdits:hover {
    background: #8c40dd 0% 0% no-repeat padding-box;
  }

  .saveEdits:active {
    background: #7a32c4;
    transform: scale(0.98);
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
  }

  .softCapTargetPercentage {
    background-color: #009144;
  }
}

@media screen and (min-width: 1920px) {

  .adminInputs {
    width: 50%;
    margin-top: 60px;
  }

  .proceed {
    background-color: #8c40dd;
    color: white;
    margin: auto;
    display: block;
    border: none;
    padding: 15px;
    border-radius: 100px;
    width: 250px;
    font-size: 24px;
    transition: 0.3s ease-in-out;
  }
  
  #confirmPledgeForm {
    margin-top: 50px;
  }

  .admin-panel {
    background-color: #2c2c2c;
    border: 1px solid #b9b9b9;
    border-radius: 8px;
    padding: 20px;
    width: 400px;
  }

  .admin-panel h2 {
    color: #AD5EFF;
    font-size: 34px;
    margin-bottom: 30px;
    text-align: center;
  }

  .form-group {
    margin-bottom: 15px;
  }

  .form-group label {
    display: block;
    font-size: 20px;
    margin-bottom: 15px;
    text-align: left;
  }

  .form-group input {
    width: 600px;
    padding: 12px;
    border: 1px solid #b9b9b9;
    border-radius: 7px;
    background-color: #1a1a1a;
    color: #b9b9b9;
    font-size: 22px;
    margin-top: 4px;
    margin-bottom: 4px;
  }

  .form-group input::placeholder {
    color: #616161;
  }

  .form-group input[type="number"] {
    -moz-appearance: textfield;
  }

  .form-group input[type="number"]::-webkit-outer-spin-button,
  .form-group input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .form-group button {
    width: 600px;
    padding: 15px;
    border: none;
    border-radius: 7px;
    background-color: #AD5EFF;
    color: white;
    font-size: 22px;
    cursor: pointer;
  }

  .form-group button:hover {
    background-color: #962EEB;
  }

  .responseSuccess {
    font-weight: 700;
    text-align: center;
    font-size: 32px;

  }

  .review {
    color: #5fb619;
    font-weight: 500;
    font-size: 32px;
  }

  .review2 {
    color: red;
    font-weight: 500;
    font-size: 32px;
  }

  .popup-containerMain3 {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100vh;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .popup-container3 {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.5);
    width: 30%;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }


  .success {
    background-color: #ffffff;
    border-color: #d6e9c6;
  }

  .close2 {
    right: 5px;
    margin-top: 5px;
    cursor: pointer;
    font-size: 34px;
    z-index: 50;
    color: #b9b9b9;
    transition: 0.3s ease-in-out;
    text-align: right;
  }

  .close2:hover {
    color: #a0a0a0;
  }


  .regsiterFlex {
    display: flex;
    justify-content: space-evenly;
  }

  /* Overlay for dimming the background */
  .register-overlay,
  .login-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.63);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
  }

  .register-popup {
    background-color: white;
    border-radius: 12px;
    padding: 25px;
    padding-top: 0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    max-width: 90%;
    width: 90%;
    text-align: center;
  }

  .leftSectionWidth {
    width: 50%;
  }

  .rightSectionWidth {
    width: 30%;
  }

  /* General pop-up styling */
  .login-popup {
    background-color: white;
    border-radius: 12px;
    padding: 25px;
    padding-top: 0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    max-width: 550px;
    width: 90%;
    text-align: center;
  }

  /* Header styling */
  .register-popup h2,
  .login-popup h2 {
    font-size: 35px;
    margin-bottom: 20px;
    font-weight: bold;
  }

  /* Input fields styling */
  .register-popup input[type="text"],
  .register-popup input[type="email"],
  .register-popup input[type="password"],
  .login-popup input[type="text"],
  .login-popup input[type="password"] {
    width: 100%;
    padding: 15px;
    margin: 15px 0;
    border: 2px solid #ccc;
    border-radius: 8px;
    color: #262626;
    outline-color: #8c40dd;
    font-size: 24px;
  }

  #selectSection {
    font-size: 24px;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  #birthTxt {
    font-size: 22px;
  }

  .optionCountries {
    outline-color: #8c40dd;
    text-align: left;
  }

  .register-popup input::placeholder {
    color: #5f5f5f;
  }

  .login-popup input::placeholder {
    color: #5f5f5f;
  }

  /* Button styling */
  .register-popup .register-btn,
  .login-popup .login-btn {
    background-color: #AD5EFF;
    color: white;
    padding: 15px 28px;
    border: none;
    border-radius: 7px;
    cursor: pointer;
    font-size: 22px;
    margin-top: 25px;
    transition: 0.3s ease-in-out;
  }

  .register-popup .register-btn,
  .login-popup .login-btn:hover {
    background: #8c40dd 0% 0% no-repeat padding-box;
  }

  .register-popup .register-btn,
  .login-popup .login-btn:active {
    background: #7a32c4;
    /* Darken the color on active */
    transform: scale(0.98);
    /* Slightly reduce the size to simulate a press */
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
    /* Add an inset shadow for a pressed effect */
  }

  /* Cancel and forgot password link styling */
  .register-popup .cancel-btn,
  .login-popup .forgot-password {
    color: #666666;
    margin-top: 10px;
    display: inline-block;
    cursor: pointer;
    font-size: 20px;
    margin-left: 15px;
    text-decoration: underline;
  }

  #remember-me2 {
    margin-left: 5px;
  }

  .accountCreate {
    margin-top: 35px;
    color: #363636;
    font-size: 20px;
  }

  .rh {
    font-weight: 600;
    color: #AD5EFF;
    text-decoration: underline;
    cursor: pointer;
    transition: 0.3s ease-in-out;
  }

  .rh:hover {
    color: #c666fd;
  }

  .onlyMob {
    display: none;
  }

  .allWrap {
    background-color: #000002;
    height: 100vh;
  }


  .light {
    background-color: #000002;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    height: 100vh;
    height: max-content;
    justify-content: center;
  }


  .connectT {
    color: white;
    background-color: #AD5EFF;
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    text-decoration: underline;
    padding: 5px;
    font-weight: 500;
    cursor: pointer;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
    border-radius: 100px;
    transition: 0.3s ease-in-out;
    font-size: 22px;
    margin-bottom: 30px;
  }

  .connectT:hover {
    background: #8c40dd 0% 0% no-repeat padding-box;
  }

  .connectT:active {
    background: #7a32c4;
    /* Darken the color on active */
    transform: scale(0.98);
    /* Slightly reduce the size to simulate a press */
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
    /* Add an inset shadow for a pressed effect */
  }

  .leftSection {
    border: 0.3px solid #FCFCFC;
    border-radius: 15px;
    width: 20%;
    height: 90vh;
    height: max-content;
    margin-top: 15px;
    margin-left: 15px;
    position: sticky;
    top: 20px;
    margin-bottom: 20px;
  }

  .logo {
    width: 150px;
  }

  .logoDiv {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 25px;
    margin-bottom: 25px;
  }

  .section1 {
    border-top: #fcfcfc71 0.3px solid;
  }


  .pagesDiv {
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    margin-top: 6vh;
    margin-bottom: 7vh;
    color: white;
  }

  .pagesDiv div {
    color: white;
    font-size: 19px;
    margin-top: auto;
    margin-bottom: auto;
    margin: 15px;
    cursor: pointer;
    transition: 0.3s ease-in-out;
  }


  .pagesDiv-2 {
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    margin-top: 6vh;
    margin-bottom: 7vh;
    color: #727070;
  }

  .pagesDiv-2 div {
    color: #727070;
    font-size: 19px;
    margin-top: auto;
    margin-bottom: auto;
    margin: 15px;
    cursor: pointer;
    transition: 0.3s ease-in-out;
  }


  .headerNav {
    color: white;
  }

  .pagesDiv div:hover {
    color: #919191;
  }

  .pagesDiv img {
    width: 20px;
    height: 20px;
    margin-top: -5px;
    margin-right: 18px;
  }

  .pagesDiv-2 img {
    width: 20px;
    height: 20px;
    margin-top: -5px;
    margin-right: 18px;
  }

  .section3Headers {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .section3HeadersMain {
    border: 2px solid #AD5EFF;
    width: fit-content;
    padding: 15px;
    border-radius: 9px;
    width: 100%;
    cursor: pointer;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .amountSection {
    margin-top: 15px;
  }

  .ghutAmount2 {
    font-size: 28px;
    font-weight: 100;
    color: rgb(228, 228, 228);
    width: 150px;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
    margin-left: 25px;
  }

  .maticAmount {
    font-size: 25px;
    font-weight: 100;
    color: rgb(228, 228, 228);
    margin-left: 25px;
  }

  #amountHL {
    color: white;
    font-weight: 500;
  }

  .section3Headers img {
    width: 20px;
    height: 20px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .section3Headers div {
    color: white;
    font-size: 19px;
  }

  .grayTxt {
    color: #727070;
    transition: 0.3s ease-in-out;
  }

  .grayTxt:hover {
    color: #bbbbbb;
  }

  .section4 {
    margin-top: 10vh;
  }

  .title1Rsection {
    font-size: 23px;
  }

  .page1Main {
    display: flex;
    justify-content: space-between;
  }

  .mainT {
    color: #727070;
    margin-bottom: 20px;
    font-size: 22px;
    text-align: left;

  }

  .rightSection {
    margin-right: auto;
    margin-left: auto;
    width: 100%;
    padding: 55px;
  }

  .rightSection--2 {
    display: flex;
    justify-content: space-between;
  }

  .rightSection-1 {
    width: 65%;
  }

  .rightSection-2 {
    width: 30%;
    margin-right: auto;
  }

  .rightSection-2-2 {
    width: 30%;
    margin-left: 1%;
  }

  .title1Rsection {
    text-align: left;
    color: #FCFCFC;
    font-size: 28px;
  }

  .title2Rsection {
    color: #AD5EFF;
    font-size: 25px;
  }

  .details {
    font-size: 22px;
    margin-top: 15px;
  }

  #de1 {
    color: white;
    font-weight: 500;
  }

  #de2 {
    color: rgb(173, 173, 173);
    font-weight: 500;
  }

  #de3 {
    color: rgb(128, 128, 128);
    font-weight: 500;
  }

  .note {
    font-size: 22px;
    color: #727070ec;
    margin-top: 10px;
    width: 90%;
  }

  .flexBox {
    display: flex;
    justify-content: center;
    margin-top: 70px;
  }

  .purpleBox {
    background-color: #AD5EFF;
    border-radius: 10px;
    padding: 22px;
    width: 95%;
  }

  .blackBox {
    background-color: rgb(0, 0, 0);
    width: 95%;
    margin-top: auto;
    margin-bottom: auto;
    color: white;
    width: 90%;
    padding: 15px;
  }

  .boxT1 {
    font-size: 25px;
    font-weight: 700;
  }

  .boxT2 {
    font-size: 33px;
    font-weight: bolder;
  }

  .boxT3 {
    font-size: 22px;
    font-weight: 400;
    margin-top: 10px;
  }

  .giftSection {
    font-size: 20px;
    margin-top: 28px;
    display: flex;
    justify-content: center;
  }

  .giftSection img {
    width: 28px;
    height: 28px;
    margin-right: 15px;
    margin-top: -5px;
  }

  .giftSectionP1 {
    font-weight: bold;
  }

  .underline {
    text-decoration: underline;
    cursor: pointer;
    transition: 0.3s ease-in-out;
  }

  .underline:hover {
    color: black;
  }

  .bbTitle {
    font-size: 27px;
    font-weight: 500;
    margin-bottom: 15px;
  }

  #bbTitle2 {
    font-weight: 300;
  }

  .bbTitle3 {
    font-size: 18px;
  }

  .box2Main {
    width: 85%;
    margin-left: auto;
    margin-right: auto;
    text-align: justify;
  }

  .imgs2_1 {
    width: 125px;
    margin-right: 12px;
  }

  .imgs2_2 {
    width: 55px;
    margin-left: 12px;

  }

  .imgs2BB {
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
    justify-content: center;
  }

  .line {
    width: 100%;
    height: 1.5px;
    background-color: rgba(255, 255, 255, 0.26);
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .flexBox2T {
    font-size: 22px;
    color: #AD5EFF;
    font-weight: 500;
    margin-bottom: 45px;
  }

  .flexBox3T {
    font-size: 34px;
    color: #AD5EFF;
    font-weight: 500;
    font-weight: 300;
  }

  .flexBox4T {
    font-size: 36px;
    color: #ffffff;
    font-weight: 300;
    margin-bottom: 50px;
    width: 100%;
  }

  .amountBox1 {
    border: 0.5px solid #fcfcfc6e;
    opacity: 1;
    border-radius: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 50%;
    padding: 15px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

  }

  .amountBox2 {
    width: 50%;
    display: flex;
    justify-content: center;
    /* Center horizontally */
    align-items: center;
    /* Center vertically */
    border: 0.5px solid #fcfcfc6e;
    border-left: 0.5px solid #fcfcfc00;
    opacity: 1;
    border-radius: 9px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

  }

  .amountBoxMain {
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
    color: white;
    display: flex;
    justify-content: center;
    margin-top: 25px;
    margin-bottom: 30px;
  }

  .sendCoin {
    font-size: 20.5px;
    font-weight: 400;
    margin-top: -30px;
    background-color: black;
    width: fit-content;
    font-weight: 300;
  }

  .amount {
    font-size: 37px;
    font-weight: 400;
    text-align: center;
  }

  .amountBox button {
    background: var(--violeta) 0% 0% no-repeat padding-box;
    background: #AD5EFF 0% 0% no-repeat padding-box;
    border-radius: 7px;
    opacity: 1;
    border: none;
    color: white;
    width: 100%;
    padding: 7px;
    transition: 0.3s ease-in-out;
    font-size: 23px;
  }

  .amountBox button:hover {
    background: #8c40dd 0% 0% no-repeat padding-box;
  }

  .amountBox button:active {
    background: #7a32c4;
    /* Darken the color on active */
    transform: scale(0.98);
    /* Slightly reduce the size to simulate a press */
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
    /* Add an inset shadow for a pressed effect */
  }

  .usdtAmount {
    width: 45px;
    height: 45px;
  }

  .ghutAmount {
    width: 45px;
    height: 45px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .line2 {
    width: 8px;
    height: 100%;
    background-color: #727070;
  }

  .amount input {
    background-color: rgba(0, 0, 0, 0);
    width: 100%;
    border: none;
    outline: none;
    color: white;
    text-align: center;
  }

  ::placeholder {
    color: white;
  }

  .progress-container {
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
    background-color: white;
    border-radius: 100px;
  }

  .progress-bar {
    background-color: rgb(206, 206, 206);
    height: 30px;
    border-radius: 100px;
    padding: 17px;
    font-size: 14px;
  }

  .progress-container2 {
    margin-top: 10px;
    width: 100%;
    background-color: white;
    border-radius: 100px;

  }

  ._softCapTargetPercentage {
    background-color: #000000;
    border-radius: 100px;
    font-size: 17px;
    text-align: center;
    font-weight: bold;
    margin-top: -51.5px;
    color: white;
    border: 2px solid rgb(0, 0, 0);
    padding: 0.3px;
  }

  .noteMm {
    text-align: center;
    color: white;
    margin-top: 38px;
    font-size: 22px;
  }

  .installMm {
    text-align: center;
    color: white;
    text-decoration: underline;
    transition: 0.3s ease-in-out;
    font-size: 22px;
  }

  .installMm:hover {
    cursor: pointer;
    color: #727070;
  }

  .transaction-list-main {
    background-color: #141414;
    /* Dark background */
    border-radius: 15px;
    padding: 30px;
    width: 92%;
    border: 1px solid rgba(37, 37, 37, 0.068);
  }

  .uploadMain {
    background-color: #141414;
    /* Dark background */
    border-radius: 15px;
    padding: 30px;
    width: 80%;
    border: 1px solid rgba(37, 37, 37, 0.068);
  }

  .transaction-list {
    display: flex;
    width: 97%;
    justify-content: space-between;
    margin: 20px;
    margin-top: 36px;
    margin-bottom: 36px;
    margin-left: auto;
    margin-right: auto;
  }

  .list1 {
    display: flex;
  }

  .list1 img {
    width: 38px;
    height: 38px;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 12px;
  }

  .list1Con {
    color: #8c40dd;
    font-weight: 500;
    font-size: 22px;
  }

  .list1Con2 {
    color: #616161;
    font-weight: 300;
    font-size: 21px;
  }

  .listConMain {
    margin-top: auto;
    margin-bottom: auto;
  }

  .listConMain2 {
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    color: #c7c7c7;
    margin-top: auto;
    margin-bottom: auto;
  }

  .listConMain2-2 {
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 15px;
    font-size: 22px;
  }

  .listConMain3 {
    display: flex;
    background-color: #262626;
    color: #c7c7c7;
    width: 200px;
    justify-content: space-between;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 8px;
    padding-bottom: 8px;
    border-radius: 15px;
    font-size: 22px;
  }

  .listConMain3 img {
    width: 28px;
    height: 28px;
    margin-top: auto;
    margin-bottom: auto;
    cursor: pointer;
    transition: transform .2s;
  }

  .listConMain3 img:hover {
    transform: scale(1.1);
  }

  .listBtn:hover {
    background: #8c40dd 0% 0% no-repeat padding-box;
  }

  .listBtn:active {
    background: #7a32c4;
    /* Darken the color on active */
    transform: scale(0.98);
    /* Slightly reduce the size to simulate a press */
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
    /* Add an inset shadow for a pressed effect */
  }

  .list1Con3 {
    color: #616161;
    font-size: 19px;
  }

  .listBtn {
    background-color: #AD5EFF;
    border: none;
    border-radius: 10px;
    color: white;
    font-weight: bold;
    width: 150px;
    padding: 8px;
    transition: 0.3s ease-in-out;
    font-size: 19px;

  }

  .listT {
    font-weight: 700;
    color: white;
    width: 100%;
    margin-left: 2%;
    font-size: 22px;
  }

  .line3 {
    width: 100%;
    height: 3px;
    background-color: rgba(255, 255, 255, 0.418);
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .listNote {
    text-align: center;
    color: #AD5EFF;
    font-size: 16px;
    margin-top: 5%;
  }

  .profile {
    display: flex;
    justify-content: space-between;
    margin-top: 5%;
  }

  .upload img {
    width: 200px;
  }

  .upload div {
    color: #AD5EFF;
    text-align: center;
    font-size: 22px;
  }

  label {
    color: #AD5EFF;
    text-align: center;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    flex-flow: column nowrap;
  }

  label:hover {
    color: #bf87fc;
  }

  .upload2 {
    width: 72%;
  }

  .editInputArea {
    display: flex;
    justify-content: space-between;
    margin-bottom: 48px;
    margin-top: 23px;
    border-bottom: #363636 1px solid;
    margin-left: 15px;
  }

  .editHere {
    display: flex;
  }

  .editHere div {
    margin-top: auto;
    margin-bottom: auto;
    color: #AD5EFF;
    margin-left: 15px;
    font-size: 20px;
    cursor: pointer;
  }

  .editHere img {
    width: 24px;
    height: 24px;
    margin-top: auto;
    margin-bottom: auto;
    cursor: pointer;
  }

  .editInputArea input {
    background-color: #00000200;
    width: 100%;
    border: none;
    outline: none;
    font-size: 22px;
    color: #dadada;
  }

  .editInputArea input::placeholder {
    color: #616161;
  }

  .editT {
    color: #616161;
    font-size: 16px;
    font-weight: bolder;
  }

  .grid {
    width: 8%;
    z-index: 5;
    position: fixed;
    right: 0;
    bottom: 0;
  }

  .popup-containerMain {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 5;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100vh;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    animation: fadeIn 0.4s ease-in-out forwards;

  }

  .close {
    position: absolute;
    top: 5px;
    right: 15px;
    cursor: pointer;
    font-size: 30px;
    z-index: 50;
    color: #b9b9b9;
    transition: 0.3s ease-in-out;
  }

  .close:hover {
    color: #a0a0a0;
  }

  .popup-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 5;
    background-color: rgba(0, 0, 0, 0.5);
    width: 500px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: #009144;
    padding: 33px;
    border-radius: 15px;
  }

  .popup-container2 {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 5;
    background-color: rgba(0, 0, 0, 0.5);
    width: 500px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: #E90000;
    padding: 33px;
    border-radius: 15px;
  }

  .popup-container3 {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 5;
    background-color: rgba(0, 0, 0, 0.5);
    width: 600px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: #ffffff;
    padding: 33px;
    border-radius: 15px;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translate(-50%, -45%);
    }

    100% {
      opacity: 1;
      transform: translate(-50%, -50%);
    }
  }

  .validateT {
    color: #FCFCFC;
    opacity: 1;
    text-align: center;
    font-weight: 500;
    margin-top: 25px;
    font-size: 21px;
    text-transform: uppercase;
    font-weight: bold;
  }

  .errorT {
    color: #FCFCFC;
    opacity: 1;
    text-align: center;
    font-weight: 500;
    margin-top: 15px;
    font-size: 24px;
    font-weight: bold;
    text-transform: uppercase;
  }

  .editTPopUp {
    text-align: left;
    color: #262626;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 19px;
  }

  .validateInputDiv {
    flex-flow: column nowrap;
    display: flex;
    margin-bottom: 30px;
    margin-top: 40px;
  }

  .validateInputDiv input {
    border: 3px solid rgba(255, 255, 255, 0.726);
    color: white;
    opacity: 1;
    margin-bottom: 15px;
    border-radius: 10px;
    background-color: #36363600;
    padding: 9px;
    padding-left: 20px;
    outline: none;
    font-size: 22px;
  }


  .validateInputDiv2 {
    flex-flow: column nowrap;
    display: flex;
    margin-bottom: 40px;
    margin-top: 40px;
  }

  .validateInputDiv2 input {
    border: 3px solid rgba(255, 255, 255, 0.726);
    color: rgba(44, 44, 44, 0);
    opacity: 1;
    background-color: #36363600;
    border-bottom: 3px solid #3f3f3f54;
    outline: none;
    padding-bottom: 9px;
    font-size: 22px;
    color: #191919;
  }

  .validateInputDiv2 input::placeholder {
    color: #191919;
  }

  .validate {
    background-color: white;
    color: black;
    border-radius: 15px;
    border: none;
    padding: 12px;
    font-weight: bold;
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 190px;
    font-size: 22px;
  }

  .validate:hover {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transition: 0.3s ease-in-out;
  }

  .validate:active {
    background: #cccccc;
    transform: scale(0.98);
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
  }

  .errorFlexBtns {
    display: flex;
    justify-content: center;
    margin-top: 45px;
  }

  .errorP {
    color: white;
    opacity: 1;
    text-align: center;
    margin-top: 15px;
    font-size: 22px;
  }

  .saveEdits {
    background-color: #AD5EFF;
    color: rgb(255, 255, 255);
    border-radius: 12px;
    border: none;
    padding: 12px;
    display: block;
    width: 170px;
    margin-left: auto;
    transition: 0.3s ease-in-out;
    font-size: 22px;
  }

  .saveEdits:hover {
    background: #8c40dd 0% 0% no-repeat padding-box;
  }

  .saveEdits:active {
    background: #7a32c4;
    transform: scale(0.98);
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
  }

  .softCapTargetPercentage {
    background-color: #009144;
  }
}

@media screen and (min-width: 1921px) and (max-width: 3839px) {
  .adminInputs {
    width: 50%;
    margin-top: 80px;
  }

  #confirmPledgeForm {
    margin-top: 90px;
  }

  .proceed {
    background-color: #8c40dd;
    color: white;
    margin: auto;
    display: block;
    border: none;
    padding: 18px;
    border-radius: 100px;
    width: 450px;
    font-size: 32px;
    transition: 0.3s ease-in-out;
    margin-top: 15px;
  }
  
  .admin-panel {
    background-color: #2c2c2c;
    border: 1px solid #b9b9b9;
    border-radius: 8px;
    padding: 20px;
    width: 400px;
  }

  .admin-panel h2 {
    color: #AD5EFF;
    font-size: 34px;
    margin-bottom: 30px;
    text-align: center;
  }

  .form-group {
    margin-bottom: 15px;
  }

  .form-group label {
    display: block;
    font-size: 25px;
    margin-bottom: 15px;
    text-align: left;
  }

  .form-group input {
    width: 800px;
    padding: 15px;
    border: 1px solid #b9b9b9;
    border-radius: 8px;
    background-color: #1a1a1a;
    color: #b9b9b9;
    font-size: 32px;
    margin-top: 6px;
    margin-bottom: 6px;
  }

  .form-group input::placeholder {
    color: #616161;
  }

  .form-group input[type="number"] {
    -moz-appearance: textfield;
  }

  .form-group input[type="number"]::-webkit-outer-spin-button,
  .form-group input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .form-group button {
    width: 800px;
    padding: 20px;
    border: none;
    border-radius: 10px;
    background-color: #AD5EFF;
    color: white;
    font-size: 32px;
    cursor: pointer;
  }

  .form-group button:hover {
    background-color: #962EEB;
  }

  .responseSuccess {
    font-weight: 700;
    text-align: center;
    font-size: 32px;

  }

  .review {
    color: #5fb619;
    font-weight: 500;
    font-size: 32px;

  }

  .review2 {
    color: red;
    font-weight: 500;
    font-size: 32px;

  }


  .popup-containerMain3 {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100vh;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .popup-container3 {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.5);
    width: 30%;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .success {
    background-color: #ffffff;
    border-color: #d6e9c6;
  }

  .close2 {
    right: 10px;
    margin-top: 10px;
    cursor: pointer;
    font-size: 44px;
    z-index: 50;
    color: #b9b9b9;
    transition: 0.3s ease-in-out;
    text-align: right;
  }

  .close2:hover {
    color: #a0a0a0;
  }


  .regsiterFlex {
    display: flex;
    justify-content: space-evenly;
  }

  /* Overlay for dimming the background */
  .register-overlay,
  .login-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.63);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
  }

  .register-popup {
    background-color: white;
    border-radius: 15px;
    padding: 25px;
    padding-top: 0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    max-width: 90%;
    width: 90%;
    text-align: center;
  }

  .leftSectionWidth {
    width: 50%;
  }

  .rightSectionWidth {
    width: 30%;
  }

  /* General pop-up styling */
  .login-popup {
    background-color: white;
    border-radius: 15px;
    padding: 25px;
    padding-top: 0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    max-width: 700px;
    width: 90%;
    text-align: center;
  }

  /* Header styling */
  .register-popup h2,
  .login-popup h2 {
    font-size: 50px;
    margin-bottom: 30px;
    font-weight: bold;
  }

  /* Input fields styling */
  .register-popup input[type="text"],
  .register-popup input[type="email"],
  .register-popup input[type="password"],
  .login-popup input[type="text"],
  .login-popup input[type="password"] {
    width: 100%;
    padding: 15px;
    margin: 20px 0;
    border: 3px solid #ccc;
    border-radius: 10px;
    color: #262626;
    outline-color: #8c40dd;
    font-size: 32px;
  }

  #selectSection {
    font-size: 32px;
    margin-top: 22px;
    margin-bottom: 22px;
  }

  #birthTxt {
    font-size: 32px;
  }

  .optionCountries {
    outline-color: #8c40dd;
    text-align: left;
  }

  .register-popup input::placeholder {
    color: #5f5f5f;
  }

  .login-popup input::placeholder {
    color: #5f5f5f;
  }

  /* Button styling */
  .register-popup .register-btn,
  .login-popup .login-btn {
    background-color: #AD5EFF;
    color: white;
    padding: 17px 28px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    font-size: 32px;
    margin-top: 35px;
    transition: 0.3s ease-in-out;
  }

  .register-popup .register-btn,
  .login-popup .login-btn:hover {
    background: #8c40dd 0% 0% no-repeat padding-box;
  }

  .register-popup .register-btn,
  .login-popup .login-btn:active {
    background: #7a32c4;
    /* Darken the color on active */
    transform: scale(0.98);
    /* Slightly reduce the size to simulate a press */
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
    /* Add an inset shadow for a pressed effect */
  }

  /* Cancel and forgot password link styling */
  .register-popup .cancel-btn,
  .login-popup .forgot-password {
    color: #666666;
    margin-top: 10px;
    display: inline-block;
    cursor: pointer;
    font-size: 28px;
    margin-left: 25px;
    text-decoration: underline;
  }

  #remember-me2 {
    margin-left: 5px;
  }

  .accountCreate {
    margin-top: 45px;
    color: #363636;
    font-size: 25px;
  }

  .rh {
    font-weight: 600;
    color: #AD5EFF;
    text-decoration: underline;
    cursor: pointer;
    transition: 0.3s ease-in-out;
  }

  .rh:hover {
    color: #c666fd;
  }

  .onlyMob {
    display: none;
  }

  .allWrap {
    background-color: #000002;
    height: 100vh;
  }


  .light {
    background-color: #000002;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    height: 100vh;
    height: max-content;
    justify-content: center;
  }

  .connectT {
    color: white;
    background-color: #AD5EFF;
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    text-decoration: underline;
    padding: 5px;
    font-weight: 500;
    cursor: pointer;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    border-radius: 100px;
    transition: 0.3s ease-in-out;
    font-size: 32px;
    margin-bottom: 30px;
  }

  .connectT:hover {
    background: #8c40dd 0% 0% no-repeat padding-box;
  }

  .connectT:active {
    background: #7a32c4;
    /* Darken the color on active */
    transform: scale(0.98);
    /* Slightly reduce the size to simulate a press */
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
    /* Add an inset shadow for a pressed effect */
  }

  .leftSection {
    border: 0.3px solid #FCFCFC;
    border-radius: 15px;
    width: 20%;
    height: 90vh;
    height: max-content;
    margin-top: 15px;
    margin-left: 15px;
    position: sticky;
    top: 20px;
    margin-bottom: 20px;
  }

  .logo {
    width: 190px;
  }

  .logoDiv {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 35px;
    margin-bottom: 35px;
  }

  .section1 {
    border-top: #fcfcfc71 0.3px solid;
  }


  .pagesDiv {
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    margin-top: 6vh;
    margin-bottom: 7vh;
    color: white;
  }

  .pagesDiv div {
    color: white;
    font-size: 28px;
    margin-top: auto;
    margin-bottom: auto;
    margin: 20px;
    cursor: pointer;
    transition: 0.3s ease-in-out;
  }

  .pagesDiv-2 {
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    margin-top: 6vh;
    margin-bottom: 7vh;
    color: #727070;
  }

  .pagesDiv-2 div {
    color: #727070;
    font-size: 28px;
    margin-top: auto;
    margin-bottom: auto;
    margin: 20px;
    cursor: pointer;
    transition: 0.3s ease-in-out;
  }


  .headerNav {
    color: white;
  }

  .pagesDiv div:hover {
    color: #919191;
  }

  .pagesDiv img {
    width: 32px;
    height: 32px;
    margin-top: -5px;
    margin-right: 22px;
  }

  .pagesDiv-2 img {
    width: 32px;
    height: 32px;
    margin-top: -5px;
    margin-right: 22px;
  }

  .section3Headers {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .section3HeadersMain {
    border: 2px solid #AD5EFF;
    width: fit-content;
    padding: 15px;
    border-radius: 12px;
    width: 100%;
    cursor: pointer;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .amountSection {
    margin-top: 22px;
  }

  .ghutAmount2 {
    font-size: 37px;
    font-weight: 100;
    color: rgb(228, 228, 228);
    width: 200px;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
    margin-left: 32px;
  }

  .maticAmount {
    font-size: 34px;
    font-weight: 100;
    color: rgb(228, 228, 228);
    margin-left: 32px;
  }

  #amountHL {
    color: white;
    font-weight: 500;
  }

  .section3Headers img {
    width: 28px;
    height: 28px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .section3Headers div {
    color: white;
    font-size: 27px;
  }

  .grayTxt {
    color: #727070;
    transition: 0.3s ease-in-out;
  }

  .grayTxt:hover {
    color: #bbbbbb;
  }

  .section4 {
    margin-top: 10vh;
  }

  .title1Rsection {
    font-size: 23px;
  }

  .page1Main {
    display: flex;
    justify-content: space-between;
  }

  .mainT {
    color: #727070;
    margin-bottom: 30px;
    font-size: 32px;
    text-align: left;

  }

  .rightSection {
    margin-right: auto;
    margin-left: auto;
    width: 100%;
    padding: 75px;
  }

  .rightSection--2 {
    display: flex;
    justify-content: space-between;
  }

  .rightSection-1 {
    width: 65%;
  }

  .rightSection-2 {
    width: 30%;
    margin-right: auto;
  }

  .rightSection-2-2 {
    width: 30%;
    margin-left: 1%;
  }

  .title1Rsection {
    text-align: left;
    color: #FCFCFC;
    font-size: 43px;
  }

  .title2Rsection {
    color: #AD5EFF;
    font-size: 38px;
  }

  .details {
    font-size: 32px;
    margin-top: 20px;
  }

  #de1 {
    color: white;
    font-weight: 500;
  }

  #de2 {
    color: rgb(173, 173, 173);
    font-weight: 500;
  }

  #de3 {
    color: rgb(128, 128, 128);
    font-weight: 500;
  }

  .note {
    font-size: 32px;
    color: #727070ec;
    margin-top: 15px;
    width: 90%;
  }

  .flexBox {
    display: flex;
    justify-content: center;
    margin-top: 70px;
  }

  .purpleBox {
    background-color: #AD5EFF;
    border-radius: 15px;
    padding: 36px;
    width: 95%;
  }

  .blackBox {
    background-color: rgb(0, 0, 0);
    width: 95%;
    margin-top: auto;
    margin-bottom: auto;
    color: white;
    width: 90%;
    padding: 20px;
  }

  .boxT1 {
    font-size: 35px;
    font-weight: 700;
  }

  .boxT2 {
    font-size: 45px;
    font-weight: bolder;
  }

  .boxT3 {
    font-size: 32px;
    font-weight: 400;
    margin-top: 18px;
  }

  .giftSection {
    font-size: 26px;
    margin-top: 38px;
    display: flex;
    justify-content: center;
  }

  .giftSection img {
    width: 36px;
    height: 36px;
    margin-right: 20px;
  }

  .giftSectionP1 {
    font-weight: bold;
  }

  .underline {
    text-decoration: underline;
    cursor: pointer;
    transition: 0.3s ease-in-out;
  }

  .underline:hover {
    color: black;
  }

  .bbTitle {
    font-size: 35px;
    font-weight: 500;
    margin-bottom: 18px;
  }

  #bbTitle2 {
    font-weight: 300;
  }

  .bbTitle3 {
    font-size: 28px;
  }

  .box2Main {
    width: 85%;
    margin-left: auto;
    margin-right: auto;
    text-align: justify;
  }

  .imgs2_1 {
    width: 160px;
    margin-right: 18px;
  }

  .imgs2_2 {
    width: 80px;
    margin-left: 18px;
  }

  .imgs2BB {
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
    justify-content: center;
  }

  .line {
    width: 100%;
    height: 2px;
    background-color: rgba(255, 255, 255, 0.26);
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .flexBox2T {
    font-size: 32px;
    color: #AD5EFF;
    font-weight: 500;
    margin-bottom: 65px;
  }

  .flexBox3T {
    font-size: 45px;
    color: #AD5EFF;
    font-weight: 500;
    font-weight: 300;
  }

  .flexBox4T {
    font-size: 52px;
    color: #ffffff;
    font-weight: 300;
    margin-bottom: 60px;
    width: 100%;
  }

  .amountBox1 {
    border: 0.5px solid #fcfcfc6e;
    opacity: 1;
    border-radius: 10px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 50%;
    padding: 28px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .amountBox2 {
    width: 50%;
    display: flex;
    justify-content: center;
    /* Center horizontally */
    align-items: center;
    /* Center vertically */
    border: 0.5px solid #fcfcfc6e;
    border-left: 0.5px solid #fcfcfc00;
    opacity: 1;
    border-radius: 12px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

  }

  .amountBoxMain {
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
    color: white;
    display: flex;
    justify-content: center;
    margin-top: 28px;
    margin-bottom: 38px;
  }

  .sendCoin {
    font-size: 27px;
    font-weight: 400;
    margin-top: -48px;
    background-color: black;
    width: fit-content;
    font-weight: 300;
  }

  .amount {
    font-size: 50px;
    font-weight: 400;
    text-align: center;
  }

  .amountBox button {
    background: var(--violeta) 0% 0% no-repeat padding-box;
    background: #AD5EFF 0% 0% no-repeat padding-box;
    border-radius: 10px;
    opacity: 1;
    border: none;
    color: white;
    width: 100%;
    padding: 10px;
    transition: 0.3s ease-in-out;
    font-size: 30px;
  }

  .amountBox button:hover {
    background: #8c40dd 0% 0% no-repeat padding-box;
  }

  .amountBox button:active {
    background: #7a32c4;
    /* Darken the color on active */
    transform: scale(0.98);
    /* Slightly reduce the size to simulate a press */
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
    /* Add an inset shadow for a pressed effect */
  }

  .usdtAmount {
    width: 60px;
    height: 60px;
  }

  .ghutAmount {
    width: 60px;
    height: 60px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .line2 {
    width: 8px;
    height: 100%;
    background-color: #727070;
  }

  .amount input {
    background-color: rgba(0, 0, 0, 0);
    width: 100%;
    border: none;
    outline: none;
    color: white;
    text-align: center;
  }

  ::placeholder {
    color: white;
  }

  .progress-container {
    margin-top: 30px;
    margin-bottom: 30px;
    width: 100%;
    background-color: white;
    border-radius: 100px;
  }

  .progress-bar {
    background-color: rgb(206, 206, 206);
    height: 40px;
    border-radius: 100px;
    padding: 25px;
    font-size: 18px;
  }

  .progress-container2 {
    margin-top: 15px;
    width: 100%;
    background-color: white;
    border-radius: 100px;
  }

  ._softCapTargetPercentage {
    background-color: #000000;
    border-radius: 100px;
    font-size: 22px;
    text-align: center;
    font-weight: bold;
    margin-top: -78px;
    color: white;
    border: 2px solid rgb(0, 0, 0);
    padding: 4px;
  }

  .noteMm {
    text-align: center;
    color: white;
    margin-top: 45px;
    font-size: 32px;
  }

  .installMm {
    text-align: center;
    color: white;
    text-decoration: underline;
    transition: 0.3s ease-in-out;
    font-size: 32px;
  }

  .installMm:hover {
    cursor: pointer;
    color: #727070;
  }

  .transaction-list-main {
    background-color: #141414;
    /* Dark background */
    border-radius: 20px;
    padding: 40px;
    width: 92%;
    border: 1px solid rgba(37, 37, 37, 0.068);
  }

  .uploadMain {
    background-color: #141414;
    /* Dark background */
    border-radius: 20px;
    padding: 40px;
    width: 80%;
    border: 1px solid rgba(37, 37, 37, 0.068);
  }

  .transaction-list {
    display: flex;
    width: 97%;
    justify-content: space-between;
    margin: 30px;
    margin-top: 50px;
    margin-bottom: 50px;
    margin-left: auto;
    margin-right: auto;
  }

  .list1 {
    display: flex;
  }

  .list1 img {
    width: 48px;
    height: 48px;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 15px;
  }

  .list1Con {
    color: #8c40dd;
    font-weight: 500;
    font-size: 32px;
  }

  .list1Con2 {
    color: #616161;
    font-weight: 300;
    font-size: 28px;
  }

  .listConMain {
    margin-top: auto;
    margin-bottom: auto;
  }

  .listConMain2 {
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    color: #c7c7c7;
    margin-top: auto;
    margin-bottom: auto;
  }

  .listConMain2-2 {
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 20px;
    font-size: 32px;
  }

  .listConMain3 {
    display: flex;
    background-color: #262626;
    color: #c7c7c7;
    width: 300px;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 20px;
    font-size: 32px;
  }

  .listConMain3 img {
    width: 34px;
    height: 34px;
    margin-top: auto;
    margin-bottom: auto;
    cursor: pointer;
    transition: transform .2s;
  }

  .listConMain3 img:hover {
    transform: scale(1.1);
  }

  .listBtn:hover {
    background: #8c40dd 0% 0% no-repeat padding-box;
  }

  .listBtn:active {
    background: #7a32c4;
    /* Darken the color on active */
    transform: scale(0.98);
    /* Slightly reduce the size to simulate a press */
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
    /* Add an inset shadow for a pressed effect */
  }

  .list1Con3 {
    color: #616161;
    font-size: 28px;
  }

  .listBtn {
    background-color: #AD5EFF;
    border: none;
    border-radius: 10px;
    color: white;
    font-weight: bold;
    width: 200px;
    padding: 12px;
    transition: 0.3s ease-in-out;
    font-size: 28px;

  }

  .listT {
    font-weight: 700;
    color: white;
    width: 100%;
    margin-left: 2%;
    font-size: 32px;
  }

  .line3 {
    width: 100%;
    height: 4px;
    background-color: rgba(255, 255, 255, 0.418);
    margin-top: 18px;
    margin-bottom: 18px;
  }

  .listNote {
    text-align: center;
    color: #AD5EFF;
    font-size: 24px;
    margin-top: 5%;
  }

  .profile {
    display: flex;
    justify-content: space-between;
    margin-top: 5%;
  }

  .upload img {
    width: 290px;
  }

  .upload div {
    color: #AD5EFF;
    text-align: center;
    font-size: 32px;
  }

  label {
    color: #AD5EFF;
    text-align: center;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    flex-flow: column nowrap;
  }

  label:hover {
    color: #bf87fc;
  }

  .upload2 {
    width: 72%;
  }

  .editInputArea {
    display: flex;
    justify-content: space-between;
    margin-bottom: 58px;
    margin-top: 38px;
    border-bottom: #363636 1px solid;
    margin-left: 20px;
  }

  .editHere {
    display: flex;
  }

  .editHere div {
    margin-top: auto;
    margin-bottom: auto;
    color: #AD5EFF;
    margin-left: 20px;
    font-size: 28px;
    cursor: pointer;
  }

  .editHere img {
    width: 36px;
    height: 36px;
    margin-top: auto;
    margin-bottom: auto;
    cursor: pointer;
  }

  .editInputArea input {
    background-color: #00000200;
    width: 100%;
    border: none;
    outline: none;
    font-size: 32px;
    color: #dadada;
  }

  .editInputArea input::placeholder {
    color: #616161;
  }

  .editT {
    color: #616161;
    font-size: 22px;
    font-weight: bolder;
  }

  .grid {
    width: 8%;
    z-index: 5;
    position: fixed;
    right: 0;
    bottom: 0;
  }

  .popup-containerMain {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 5;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100vh;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    animation: fadeIn 0.4s ease-in-out forwards;

  }

  .close {
    position: absolute;
    top: 5px;
    right: 15px;
    cursor: pointer;
    font-size: 40px;
    z-index: 50;
    color: #b9b9b9;
    transition: 0.3s ease-in-out;
  }

  .close:hover {
    color: #a0a0a0;
  }

  .popup-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 5;
    background-color: rgba(0, 0, 0, 0.5);
    width: 650px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: #009144;
    padding: 35px;
    border-radius: 15px;
  }

  .popup-container2 {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 5;
    background-color: rgba(0, 0, 0, 0.5);
    width: 650px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: #E90000;
    padding: 35px;
    border-radius: 15px;
  }

  .popup-container3 {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 5;
    background-color: rgba(0, 0, 0, 0.5);
    width: 800px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: #ffffff;
    padding: 50px;
    border-radius: 15px;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translate(-50%, -45%);
    }

    100% {
      opacity: 1;
      transform: translate(-50%, -50%);
    }
  }

  .validateT {
    color: #FCFCFC;
    opacity: 1;
    text-align: center;
    font-weight: 500;
    margin-top: 30px;
    font-size: 28px;
    text-transform: uppercase;
    font-weight: bold;
  }

  .errorT {
    color: #FCFCFC;
    opacity: 1;
    text-align: center;
    font-weight: 500;
    margin-top: 20px;
    font-size: 34px;
    font-weight: bold;
    text-transform: uppercase;
  }

  .editTPopUp {
    text-align: left;
    color: #262626;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 28px;
  }

  .validateInputDiv {
    flex-flow: column nowrap;
    display: flex;
    margin-bottom: 40px;
    margin-top: 50px;
  }

  .validateInputDiv input {
    border: 4px solid rgba(255, 255, 255, 0.726);
    color: white;
    opacity: 1;
    margin-bottom: 20px;
    border-radius: 15px;
    background-color: #36363600;
    padding: 12px;
    padding-left: 20px;
    outline: none;
    font-size: 28px;
  }


  .validateInputDiv2 {
    flex-flow: column nowrap;
    display: flex;
    margin-bottom: 50px;
    margin-top: 50px;
  }

  .validateInputDiv2 input {
    border: 3px solid rgba(255, 255, 255, 0.726);
    color: rgba(44, 44, 44, 0);
    opacity: 1;
    background-color: #36363600;
    border-bottom: 4px solid #3f3f3f54;
    outline: none;
    padding-bottom: 9px;
    font-size: 32px;
    color: #191919;
  }

  .validateInputDiv2 input::placeholder {
    color: #191919;
  }

  .validate {
    background-color: white;
    color: black;
    border-radius: 20px;
    border: none;
    padding: 15px;
    font-weight: bold;
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 240px;
    font-size: 30px;
  }

  .validate:hover {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transition: 0.3s ease-in-out;
  }

  .validate:active {
    background: #cccccc;
    transform: scale(0.98);
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
  }

  .errorFlexBtns {
    display: flex;
    justify-content: center;
    margin-top: 65px;
  }

  .errorP {
    color: white;
    opacity: 1;
    text-align: center;
    margin-top: 25px;
    font-size: 30px;
  }

  .saveEdits {
    background-color: #AD5EFF;
    color: rgb(255, 255, 255);
    border-radius: 15px;
    border: none;
    padding: 10px;
    display: block;
    width: 250px;
    margin-left: auto;
    transition: 0.3s ease-in-out;
    font-size: 32px;
  }

  .saveEdits:hover {
    background: #8c40dd 0% 0% no-repeat padding-box;
  }

  .saveEdits:active {
    background: #7a32c4;
    transform: scale(0.98);
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
  }

  .softCapTargetPercentage {
    background-color: #009144;
  }
}

@media screen and (min-width: 3840px) {
  .adminInputs {
    width: 50%;
    margin-top: 80px;
  }
  .proceed {
    background-color: #8c40dd;
    color: white;
    margin: auto;
    display: block;
    border: none;
    padding: 22px;
    border-radius: 100px;
    width: 500px;
    font-size: 42px;
    transition: 0.3s ease-in-out;
    margin-top: 15px;
  }
  
  #confirmPledgeForm {
    margin-top: 90px;
  }

  .admin-panel {
    background-color: #2c2c2c;
    border: 1px solid #b9b9b9;
    border-radius: 8px;
    padding: 20px;
    width: 400px;
  }

  .admin-panel h2 {
    color: #AD5EFF;
    font-size: 34px;
    margin-bottom: 30px;
    text-align: center;
  }

  .form-group {
    margin-bottom: 15px;
  }

  .form-group label {
    display: block;
    font-size: 40px;
    margin-bottom: 20spx;
    text-align: left;
  }

  .form-group input {
    width: 1200px;
    padding: 25px;
    border: 1px solid #b9b9b9;
    border-radius: 12px;
    background-color: #1a1a1a;
    color: #b9b9b9;
    font-size: 42px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .form-group input::placeholder {
    color: #616161;
  }

  .form-group input[type="number"] {
    -moz-appearance: textfield;
  }

  .form-group input[type="number"]::-webkit-outer-spin-button,
  .form-group input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .form-group button {
    width: 1200px;
    padding: 25px;
    border: none;
    border-radius: 15px;
    background-color: #AD5EFF;
    color: white;
    font-size: 42px;
    cursor: pointer;
  }

  .form-group button:hover {
    background-color: #962EEB;
  }

  .responseSuccess {
    font-weight: 700;
    text-align: center;
    font-size: 45px;
  }

  .review {
    color: #5fb619;
    font-weight: 500;
    text-align: center;
    font-size: 45px;
  }

  .review2 {
    color: red;
    font-weight: 500;
    text-align: center;
    font-size: 45px;
  }

  .popup-containerMain3 {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100vh;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .popup-container3 {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.5);
    width: 30%;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }



  .success {
    background-color: #ffffff;
    border-color: #d6e9c6;
  }

  .close2 {
    right: 10px;
    margin-top: 10px;
    cursor: pointer;
    font-size: 64px;
    z-index: 50;
    color: #b9b9b9;
    transition: 0.3s ease-in-out;
    text-align: right;
  }

  .close2:hover {
    color: #a0a0a0;
  }


  .regsiterFlex {
    display: flex;
    justify-content: space-evenly;
  }

  /* Overlay for dimming the background */
  .register-overlay,
  .login-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.63);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
  }

  .register-popup {
    background-color: white;
    border-radius: 20px;
    padding: 35px;
    padding-top: 0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    max-width: 90%;
    width: 90%;
    text-align: center;
  }

  .leftSectionWidth {
    width: 50%;
  }

  .rightSectionWidth {
    width: 30%;
  }

  /* General pop-up styling */
  .login-popup {
    background-color: white;
    border-radius: 20px;
    padding: 25px;
    padding-top: 0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    max-width: 1000px;
    width: 90%;
    text-align: center;
  }

  /* Header styling */
  .register-popup h2,
  .login-popup h2 {
    font-size: 65px;
    margin-bottom: 50px;
    font-weight: bold;
  }

  /* Input fields styling */
  .register-popup input[type="text"],
  .register-popup input[type="email"],
  .register-popup input[type="password"],
  .login-popup input[type="text"],
  .login-popup input[type="password"] {
    width: 100%;
    padding: 30px;
    margin: 20px 0;
    border: 3px solid #ccc;
    border-radius: 10px;
    color: #262626;
    outline-color: #8c40dd;
    font-size: 45px;
  }

  #selectSection {
    font-size: 32px;
    margin-top: 22px;
    margin-bottom: 22px;
    font-size: 45px;
  }

  #birthTxt {
    font-size: 42px;
  }

  .optionCountries {
    outline-color: #8c40dd;
    text-align: left;
  }

  .register-popup input::placeholder {
    color: #5f5f5f;
  }

  .login-popup input::placeholder {
    color: #5f5f5f;
  }

  /* Button styling */
  .register-popup .register-btn,
  .login-popup .login-btn {
    background-color: #AD5EFF;
    color: white;
    padding: 20px 32px;
    border: none;
    border-radius: 15px;
    cursor: pointer;
    font-size: 45px;
    margin-top: 45px;
    transition: 0.3s ease-in-out;
  }

  .register-popup .register-btn,
  .login-popup .login-btn:hover {
    background: #8c40dd 0% 0% no-repeat padding-box;
  }

  .register-popup .register-btn,
  .login-popup .login-btn:active {
    background: #7a32c4;
    /* Darken the color on active */
    transform: scale(0.98);
    /* Slightly reduce the size to simulate a press */
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
    /* Add an inset shadow for a pressed effect */
  }

  /* Cancel and forgot password link styling */
  .register-popup .cancel-btn,
  .login-popup .forgot-password {
    color: #666666;
    margin-top: 10px;
    display: inline-block;
    cursor: pointer;
    font-size: 38px;
    margin-left: 35px;
    text-decoration: underline;
  }

  #remember-me2 {
    margin-left: 5px;
  }

  .accountCreate {
    margin-top: 75px;
    color: #363636;
    font-size: 40px;
  }

  .rh {
    font-weight: 600;
    color: #AD5EFF;
    text-decoration: underline;
    cursor: pointer;
    transition: 0.3s ease-in-out;
  }

  .onlyMob {
    display: none;
  }

  .light {
    background-color: #000002;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    height: 100vh;
    height: max-content;
    justify-content: center;
  }

  .allWrap {
    background-color: #000002;
    height: 100vh;
  }

  .connectT {
    color: white;
    background-color: #AD5EFF;
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    text-decoration: underline;
    padding: 10px;
    font-weight: 500;
    cursor: pointer;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    border-radius: 100px;
    transition: 0.3s ease-in-out;
    font-size: 45px;
    margin-bottom: 35px;
  }

  .connectT:hover {
    background: #8c40dd 0% 0% no-repeat padding-box;
  }

  .connectT:active {
    background: #7a32c4;
    /* Darken the color on active */
    transform: scale(0.98);
    /* Slightly reduce the size to simulate a press */
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
    /* Add an inset shadow for a pressed effect */
  }

  .leftSection {
    border: 0.3px solid #FCFCFC;
    border-radius: 30px;
    width: 20%;
    height: 90vh;
    height: max-content;
    margin-top: 15px;
    margin-left: 45px;
    position: sticky;
    top: 20px;
    margin-bottom: 20px;
  }

  .logo {
    width: 290px;
  }

  .logoDiv {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .section1 {
    border-top: #fcfcfc71 0.3px solid;
  }


  .pagesDiv {
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    margin-top: 6vh;
    margin-bottom: 7vh;
    color: white;
  }

  .pagesDiv div {
    color: white;
    font-size: 40px;
    margin-top: auto;
    margin-bottom: auto;
    margin: 32px;
    cursor: pointer;
    transition: 0.3s ease-in-out;
  }

  .pagesDiv-2 {
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    margin-top: 6vh;
    margin-bottom: 7vh;
    color: #727070;
  }

  .pagesDiv-2 div {
    color: #727070;
    font-size: 40px;
    margin-top: auto;
    margin-bottom: auto;
    margin: 32px;
    cursor: pointer;
    transition: 0.3s ease-in-out;
  }


  .headerNav {
    color: white;
  }

  .pagesDiv div:hover {
    color: #919191;
  }

  .pagesDiv img {
    width: 45px;
    height: 45px;
    margin-top: -5px;
    margin-right: 22px;
  }

  .pagesDiv-2 img {
    width: 45px;
    height: 45px;
    margin-top: -5px;
    margin-right: 22px;
  }

  .section3Headers {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .section3HeadersMain {
    border: 2px solid #AD5EFF;
    width: fit-content;
    padding: 20px;
    border-radius: 20px;
    width: 100%;
    cursor: pointer;
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .amountSection {
    margin-top: 22px;
  }

  .ghutAmount2 {
    font-size: 60px;
    font-weight: 100;
    color: rgb(228, 228, 228);
    width: 500px;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
    margin-left: 45px;
  }

  .maticAmount {
    font-size: 52px;
    font-weight: 100;
    color: rgb(228, 228, 228);
    margin-left: 45px;
  }

  #amountHL {
    color: white;
    font-weight: 500;
  }

  .section3Headers img {
    width: 32px;
    height: 32px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .section3Headers div {
    color: white;
    font-size: 42px;
  }

  .grayTxt {
    color: #727070;
    transition: 0.3s ease-in-out;
  }

  .grayTxt:hover {
    color: #bbbbbb;
  }

  .section4 {
    margin-top: 10vh;
  }

  .title1Rsection {
    font-size: 23px;
  }

  .page1Main {
    display: flex;
    justify-content: space-between;
  }

  .mainT {
    color: #727070;
    margin-bottom: 50px;
    font-size: 45px;
    text-align: left;

  }

  .rightSection {
    margin-right: auto;
    margin-left: auto;
    width: 100%;
    padding: 110px;
  }

  .rightSection--2 {
    display: flex;
    justify-content: space-between;
  }

  .rightSection-1 {
    width: 65%;
  }

  .rightSection-2 {
    width: 30%;
    margin-right: auto;
  }

  .rightSection-2-2 {
    width: 30%;
    margin-left: 1%;
  }

  .title1Rsection {
    text-align: left;
    color: #FCFCFC;
    font-size: 65px;
  }

  .title2Rsection {
    color: #AD5EFF;
    font-size: 58px;
  }

  .details {
    font-size: 45px;
    margin-top: 30px;
  }

  #de1 {
    color: white;
    font-weight: 500;
  }

  #de2 {
    color: rgb(173, 173, 173);
    font-weight: 500;
  }

  #de3 {
    color: rgb(128, 128, 128);
    font-weight: 500;
  }

  .note {
    font-size: 45px;
    color: #727070ec;
    margin-top: 30px;
    width: 90%;
  }

  .flexBox {
    display: flex;
    justify-content: center;
    margin-top: 130px;
  }

  .purpleBox {
    background-color: #AD5EFF;
    border-radius: 20px;
    padding: 45px;
    width: 95%;
  }

  .blackBox {
    background-color: rgb(0, 0, 0);
    width: 95%;
    margin-top: auto;
    margin-bottom: auto;
    color: white;
    width: 90%;
    padding: 30px;
  }

  .boxT1 {
    font-size: 52px;
    font-weight: 700;
  }

  .boxT2 {
    font-size: 68px;
    font-weight: bolder;
  }

  .boxT3 {
    font-size: 45px;
    font-weight: 400;
    margin-top: 25px;
  }

  .giftSection {
    font-size: 38px;
    margin-top: 65px;
    display: flex;
    justify-content: center;
  }

  .giftSection img {
    width: 53px;
    height: 53px;
    margin-right: 30px;
  }

  .giftSectionP1 {
    font-weight: bold;
  }

  .underline {
    text-decoration: underline;
    cursor: pointer;
    transition: 0.3s ease-in-out;
  }

  .underline:hover {
    color: black;
  }

  .bbTitle {
    font-size: 50px;
    font-weight: 500;
    margin-bottom: 22px;
  }

  #bbTitle2 {
    font-weight: 300;
  }

  .bbTitle3 {
    font-size: 42px;
  }

  .box2Main {
    width: 85%;
    margin-left: auto;
    margin-right: auto;
    text-align: justify;
  }

  .imgs2_1 {
    width: 230px;
    margin-right: 30px;
  }

  .imgs2_2 {
    width: 110px;
    margin-left: 30px;
  }

  .imgs2BB {
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
    justify-content: center;
  }

  .line {
    width: 100%;
    height: 2px;
    background-color: rgba(255, 255, 255, 0.26);
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .flexBox2T {
    font-size: 47px;
    color: #AD5EFF;
    font-weight: 500;
    margin-bottom: 100px;
  }

  .flexBox3T {
    font-size: 70px;
    color: #AD5EFF;
    font-weight: 500;
    font-weight: 300;
  }

  .flexBox4T {
    font-size: 70px;
    color: #ffffff;
    font-weight: 300;
    margin-bottom: 60px;
    width: 100%;
  }

  .amountBox1 {
    border: 0.5px solid #fcfcfc6e;
    opacity: 1;
    border-radius: 10px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 50%;
    padding: 35px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .amountBox2 {
    width: 50%;
    display: flex;
    justify-content: center;
    /* Center horizontally */
    align-items: center;
    /* Center vertically */
    border: 0.5px solid #fcfcfc6e;
    border-left: 0.5px solid #fcfcfc00;
    opacity: 1;
    border-radius: 16px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .amountBoxMain {
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
    color: white;
    display: flex;
    justify-content: center;
    margin-top: 45px;
    margin-bottom: 65px;
  }

  .sendCoin {
    font-size: 42px;
    font-weight: 400;
    margin-top: -68px;
    background-color: black;
    width: fit-content;
    font-weight: 300;
  }

  .amount {
    font-size: 70px;
    font-weight: 400;
    text-align: center;
  }

  .amountBox button {
    background: var(--violeta) 0% 0% no-repeat padding-box;
    background: #AD5EFF 0% 0% no-repeat padding-box;
    border-radius: 15px;
    opacity: 1;
    border: none;
    color: white;
    width: 100%;
    padding: 15px;
    transition: 0.3s ease-in-out;
    font-size: 45px;
    margin-top: 15px;
  }

  .amountBox button:hover {
    background: #8c40dd 0% 0% no-repeat padding-box;
  }

  .amountBox button:active {
    background: #7a32c4;
    /* Darken the color on active */
    transform: scale(0.98);
    /* Slightly reduce the size to simulate a press */
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
    /* Add an inset shadow for a pressed effect */
  }

  .usdtAmount {
    width: 85px;
    height: 85px;
  }

  .ghutAmount {
    width: 85px;
    height: 85px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .line2 {
    width: 8px;
    height: 100%;
    background-color: #727070;
  }

  .amount input {
    background-color: rgba(0, 0, 0, 0);
    width: 100%;
    border: none;
    outline: none;
    color: white;
    text-align: center;
  }

  ::placeholder {
    color: white;
  }

  .progress-container {
    margin-top: 30px;
    margin-bottom: 30px;
    width: 100%;
    background-color: white;
    border-radius: 100px;
  }

  .progress-bar {
    background-color: rgb(206, 206, 206);
    height: 40px;
    border-radius: 100px;
    padding: 33px;
    font-size: 24px;
  }

  .progress-container2 {
    margin-top: 15px;
    width: 100%;
    background-color: white;
    border-radius: 100px;
  }

  ._softCapTargetPercentage {
    background-color: #000000;
    border-radius: 100px;
    font-size: 32px;
    text-align: center;
    font-weight: bold;
    margin-top: -93px;
    color: white;
    border: 2px solid rgb(0, 0, 0);
    padding: 2px;
  }

  .noteMm {
    text-align: center;
    color: white;
    margin-top: 65px;
    font-size: 45px;
  }

  .installMm {
    text-align: center;
    color: white;
    text-decoration: underline;
    transition: 0.3s ease-in-out;
    font-size: 45px;
  }

  .installMm:hover {
    cursor: pointer;
    color: #727070;
  }

  .transaction-list-main {
    background-color: #141414;
    /* Dark background */
    border-radius: 30px;
    padding: 60px;
    width: 92%;
    border: 2px solid rgba(37, 37, 37, 0.068);
  }

  .uploadMain {
    background-color: #141414;
    /* Dark background */
    border-radius: 20px;
    padding: 40px;
    width: 80%;
    border: 1px solid rgba(37, 37, 37, 0.068);
  }

  .transaction-list {
    display: flex;
    width: 97%;
    justify-content: space-between;
    margin: 35px;
    margin-top: 80px;
    margin-bottom: 80px;
    margin-left: auto;
    margin-right: auto;
  }

  .list1 {
    display: flex;
  }

  .list1 img {
    width: 75px;
    height: 75px;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 25px;
  }

  .list1Con {
    color: #8c40dd;
    font-weight: 500;
    font-size: 45px;
  }

  .list1Con2 {
    color: #616161;
    font-weight: 300;
    font-size: 45px;
  }

  .listConMain {
    margin-top: auto;
    margin-bottom: auto;
  }

  .listConMain2 {
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    color: #c7c7c7;
    margin-top: auto;
    margin-bottom: auto;
  }

  .listConMain2-2 {
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 30px;
    font-size: 42px;
  }

  .listConMain3 {
    display: flex;
    background-color: #262626;
    color: #c7c7c7;
    width: 400px;
    justify-content: space-between;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 15px;
    padding-bottom: 15px;
    border-radius: 25px;
    font-size: 45px;
  }

  .listConMain3 img {
    width: 50px;
    height: 50px;
    margin-top: auto;
    margin-bottom: auto;
    cursor: pointer;
    transition: transform .2s;
  }

  .listConMain3 img:hover {
    transform: scale(1.1);
  }

  .listBtn:hover {
    background: #8c40dd 0% 0% no-repeat padding-box;
  }

  .listBtn:active {
    background: #7a32c4;
    /* Darken the color on active */
    transform: scale(0.98);
    /* Slightly reduce the size to simulate a press */
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
    /* Add an inset shadow for a pressed effect */
  }

  .list1Con3 {
    color: #616161;
    font-size: 38px;
  }

  .listBtn {
    background-color: #AD5EFF;
    border: none;
    border-radius: 18px;
    color: white;
    font-weight: bold;
    width: 270px;
    padding: 15px;
    transition: 0.3s ease-in-out;
    font-size: 38px;

  }

  .listT {
    font-weight: 700;
    color: white;
    width: 100%;
    margin-left: 2%;
    font-size: 45px;
  }

  .line3 {
    width: 100%;
    height: 5px;
    background-color: rgba(255, 255, 255, 0.418);
    margin-top: 32px;
    margin-bottom: 32px;
  }

  .listNote {
    text-align: center;
    color: #AD5EFF;
    font-size: 34px;
    margin-top: 5%;
  }

  .profile {
    display: flex;
    justify-content: space-between;
    margin-top: 5%;
  }

  .upload img {
    width: 400px;
  }

  .upload div {
    color: #AD5EFF;
    text-align: center;
    font-size: 45px;
  }

  label {
    color: #AD5EFF;
    text-align: center;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    flex-flow: column nowrap;
  }

  label:hover {
    color: #bf87fc;
  }

  .upload2 {
    width: 72%;
  }

  .editInputArea {
    display: flex;
    justify-content: space-between;
    margin-bottom: 75px;
    margin-top: 55px;
    border-bottom: #363636 1px solid;
    margin-left: 20px;
  }

  .editHere {
    display: flex;
  }

  .editHere div {
    margin-top: auto;
    margin-bottom: auto;
    color: #AD5EFF;
    margin-left: 20px;
    font-size: 36px;
    cursor: pointer;
  }

  .editHere img {
    width: 48px;
    height: 48px;
    margin-top: auto;
    margin-bottom: auto;
    cursor: pointer;
  }

  .editInputArea input {
    background-color: #00000200;
    width: 100%;
    border: none;
    outline: none;
    font-size: 45px;
    color: #dadada;
  }

  .editInputArea input::placeholder {
    color: #616161;
  }

  .editT {
    color: #616161;
    font-size: 32px;
    font-weight: bolder;
  }

  .grid {
    width: 8%;
    z-index: 5;
    position: fixed;
    right: 0;
    bottom: 0;
  }

  .popup-containerMain {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 5;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100vh;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    animation: fadeIn 0.4s ease-in-out forwards;

  }

  .close {
    position: absolute;
    top: 5px;
    right: 25px;
    cursor: pointer;
    font-size: 60px;
    z-index: 50;
    color: #b9b9b9;
    transition: 0.3s ease-in-out;
  }

  .close:hover {
    color: #a0a0a0;
  }

  .popup-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 5;
    background-color: rgba(0, 0, 0, 0.5);
    width: 1000px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: #009144;
    padding: 50px;
    border-radius: 20px;
  }

  .popup-container2 {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 5;
    background-color: rgba(0, 0, 0, 0.5);
    width: 1000px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: #E90000;
    padding: 50px;
    border-radius: 20px;
  }

  .popup-container3 {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 5;
    background-color: rgba(0, 0, 0, 0.5);
    width: 1000px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: #ffffff;
    padding: 70px;
    border-radius: 20px;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translate(-50%, -45%);
    }

    100% {
      opacity: 1;
      transform: translate(-50%, -50%);
    }
  }

  .validateT {
    color: #FCFCFC;
    opacity: 1;
    text-align: center;
    font-weight: 500;
    margin-top: 60px;
    font-size: 42px;
    text-transform: uppercase;
    font-weight: bold;
  }

  .errorT {
    color: #FCFCFC;
    opacity: 1;
    text-align: center;
    font-weight: 500;
    margin-top: 20px;
    font-size: 48px;
    font-weight: bold;
    text-transform: uppercase;
  }

  .editTPopUp {
    text-align: left;
    color: #262626;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 38px;
  }

  .validateInputDiv {
    flex-flow: column nowrap;
    display: flex;
    margin-bottom: 40px;
    margin-top: 50px;
  }

  .validateInputDiv input {
    border: 6px solid rgba(255, 255, 255, 0.726);
    color: white;
    opacity: 1;
    margin-bottom: 30px;
    border-radius: 20px;
    background-color: #36363600;
    padding: 18px;
    padding-left: 25px;
    outline: none;
    font-size: 43px;
  }


  .validateInputDiv2 {
    flex-flow: column nowrap;
    display: flex;
    margin-bottom: 70px;
    margin-top: 70px;
  }

  .validateInputDiv2 input {
    border: 3px solid rgba(255, 255, 255, 0.726);
    color: rgba(44, 44, 44, 0);
    opacity: 1;
    background-color: #36363600;
    border-bottom: 4px solid #3f3f3f54;
    outline: none;
    padding-bottom: 12px;
    font-size: 45px;
    color: #191919;
  }

  .validateInputDiv2 input::placeholder {
    color: #191919;
  }

  .validate {
    background-color: white;
    color: black;
    border-radius: 30px;
    border: none;
    padding: 20px;
    font-weight: bold;
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 350px;
    font-size: 45px;
  }

  .validate:hover {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transition: 0.3s ease-in-out;
  }

  .validate:active {
    background: #cccccc;
    transform: scale(0.98);
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
  }

  .errorFlexBtns {
    display: flex;
    justify-content: center;
    margin-top: 95px;
  }

  .errorP {
    color: white;
    opacity: 1;
    text-align: center;
    margin-top: 45px;
    font-size: 44px;
  }

  .saveEdits {
    background-color: #AD5EFF;
    color: rgb(255, 255, 255);
    border-radius: 20px;
    border: none;
    padding: 15px;
    display: block;
    width: 320px;
    margin-left: auto;
    transition: 0.3s ease-in-out;
    font-size: 45px;
  }

  .saveEdits:hover {
    background: #8c40dd 0% 0% no-repeat padding-box;
  }

  .saveEdits:active {
    background: #7a32c4;
    transform: scale(0.98);
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
  }

  .softCapTargetPercentage {
    background-color: #009144;
  }
}

.review2 {
  text-align: center;
}

.review {
  text-align: center;
}